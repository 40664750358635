export class LocalStorageServices{

    static setAccessTokenLocalStorageData(data){
        localStorage.setItem("access_token",data)
    }

    static getAccessTokenLocalStorageData(){
        return localStorage.getItem("access_token")
    }
    static setUserDetailsLocalStorageData(data){
        localStorage.setItem("user_details",data)
    }

    static getUserDetailsLocalStorageData(){
        return localStorage.getItem("user_details")
    }
    
    static getUserRole(){
        const UserDetails= localStorage.getItem("user_details")
        let role=undefined
        if(UserDetails){
            role=JSON.parse(UserDetails)?.role.role_name
        }
        return role

    }
    static deleteLocalStorageData(){
        localStorage.clear();
    }
    
}