import { toast } from 'react-toastify'
import { debounce } from "lodash";

let debouncedNotify = null;

const ErrorToast = (message) => {
    if (!debouncedNotify) {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            theme: "light"
        });
        debouncedNotify = debounce(() => {
            debouncedNotify = null;
        }, 2000);
        debouncedNotify();

    } else {
        debouncedNotify();
    }

}
const SuccessToast = (message) => {
    if (!debouncedNotify) {
        toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            theme: "light"
        });
        debouncedNotify = debounce(() => {
            debouncedNotify = null;
        }, 2000);
        debouncedNotify();

    } else {
        debouncedNotify();
    }
}

const InfoToast = (message) => {
    if (!debouncedNotify) {
        toast.info(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            theme: "light"
        });
        debouncedNotify = debounce(() => {
            debouncedNotify = null;
        }, 2000);
        debouncedNotify();

    } else {
        debouncedNotify();
    }
}

export { ErrorToast, SuccessToast, InfoToast }