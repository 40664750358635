import React, { useEffect, useState } from 'react'
import { DateRangePicker } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { IoMdClose } from "react-icons/io";
import './customDateRangePicker.css'
function CustomDateRangePicker({ selected_dates, onChange_date }) {
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    });
    useEffect(()=>{
        setSelectionRange({...selectionRange,['startDate']:selected_dates[0]?new Date(selected_dates[0]): new Date(),['endDate']:selected_dates[1]?new Date(selected_dates[1]): new Date()})
    },[selected_dates])
    
    const handleSelect = (ranges) => {
        const issue_start_date = ranges.selection.startDate;
        const issue_end_date = ranges.selection.endDate;
        onChange_date([issue_start_date,issue_end_date])
    };
    window.addEventListener("click", function (e) {
        try {
          if (
            !document
              .getElementById("custom_date_picker_field")
              ?.contains(e.target) &&
            !document
              .getElementById("custom_date_picker_container")
              ?.contains(e.target)
          ) {
            const element = document.getElementById("custom_date_picker_container");
            if (element) {
              if ((element.style.display = "block")) {
                element.style.display = "";
              }
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
      const handleFieldClick = () => {
        togglePickerView("custom_date_picker_container");
      };
    
      const togglePickerView = (id) => {
        const element = document.getElementById(id);
        if (element) {
          if (element.style.display === "none" || element.style.display === "") {
            element.style.display = "block";
          } else {
            element.style.display = "";
          }
        }
      };
    return (
        <div>
            <div>
                <div
                    id="custom_date_picker_field"
                    className="custom_date_picker_field"
                    onClick={handleFieldClick}
                >
                    <p style={{ display: "flex",alignItems:'center' ,flexDirection: "row" ,whiteSpace:'nowrap'}}>
                        <div>

                            {selected_dates && selected_dates[0] ? (
                               <p>{ new Date(selected_dates[0]).toLocaleString().split(',')[0].replaceAll('/','-')}</p>
                            ) : (
                                <p
                                    className="custom_date_picker_placeholder"
                                >
                                    Start date
                                </p>
                            )}
                        </div>
                        <p className="custom_date_picker_placeholder">&nbsp; - &nbsp;</p>
                        <div>
                            {selected_dates && selected_dates[1] ? (
                               <p>{  new Date(selected_dates[1]).toLocaleString().split(',')[0].replaceAll('/','-')}</p>
                            ) : (
                                <p
                                    className="custom_date_picker_placeholder"
                                >
                                    &nbsp;End date
                                </p>
                            )}
                        </div>
                    </p>
                    <div>
                        {selected_dates &&  selected_dates[0] && selected_dates[1] && (
                            <div>
                                <div
                                   className='custom_date_picker_action'
                                    onClick={() => {
                                        onChange_date(['',''])
                                    }}
                                >
                                    <p><IoMdClose /></p>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
                <div
                    id={"custom_date_picker_container"}
                    className="custom_date_picker_container"
                    style={{right:"30px"}}
                >
                    <DateRangePicker
                        ranges={[selectionRange]}
                        onChange={handleSelect}
                        className="custom-date-picker"
                    />
                </div>
            </div>
        </div>
    )
}

export default CustomDateRangePicker