import React, { useEffect } from 'react'
import './locationStyle.css'
import { useNavigate } from 'react-router-dom'
import CustomTable from '../../../../components/custom_table/CustomTable'
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { PiEye } from "react-icons/pi";
import { delete_location_by_id, get_locations_with_pagination } from '../../../../api/locationApis';
import { useState } from 'react';
import { SuccessToast } from '../../../../toast/toasts';
import CustomBottomPagination from '../../../../components/custom_bottom_pagination/CustomBottomPagination';
import LocationFilter from './location_filter/LocationFilter';
import { LocalStorageServices } from '../../../../services/localStorageServices';

function Locations() {
    const baseRouteUrl=`/ecrs_portal/${LocalStorageServices.getUserRole()}/master_settings`
    const navigate=useNavigate()
    const [locationListing,setLocationListing]=useState([])
    const [pageNumber,setPageNumber]=useState(1)
    const [totalPages,setTotalPages]=useState(0)
    const [totalItemPerPage,setTotalItemPerPage]=useState(10)
    const [searchFilter,setSearchFilter]=useState(null)
    useEffect(()=>{
      getLocations(pageNumber,searchFilter)
    },[pageNumber,totalItemPerPage])
    const getLocations=(pageNumber,filterValue)=>{
      const formData = new FormData();
      formData.append('page', pageNumber);
      formData.append('pageItem_count', totalItemPerPage);
      if(filterValue){
        for (const key in filterValue) {
          formData.append(key, filterValue[key]);
      }
      }
      get_locations_with_pagination(formData).then((response)=>{
        if(response.data.data.length>0){
        setLocationListing(response.data.data)
        setTotalPages(response.data.last_page)
        }else{
          if(pageNumber>1){
            setPageNumber(pageNumber-1)
          }
        }
      }).catch((error)=>{
        console.log(error)
      })
    }
    const Columns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key",
        
      },
      {
        title: "Location Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Contact Person Name",
        dataIndex: "contact_person_name",
        key: "contact_person_name",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Contact Number",
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "Location",
        dataIndex: "address",
        key: "address",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        wrapper: (row) => (
          <div >
            <p style={{color:row.status=='Active'?'#0A9E02':"#FF0000"}}>{row.status}</p>
          </div>
        ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        action: (row) => (
          <div style={{display:'flex',alignItems:'center','gap':5}} >
            <PiEye className='locations_action_view' onClick={()=>{
             navigate(baseRouteUrl+`/${row.id}/view_location`)
           }}/> 
            <BiEditAlt className='locations_action_edit' onClick={()=>{
              navigate(baseRouteUrl+`/${row.id}/update_location`)
            }} /> 
            <MdOutlineDeleteOutline className='locations_action_delete' onClick={()=>{
              deleteLocation(row.id)
            }}/>
          </div>
        ),
      },
    ];
  const deleteLocation=(location_id)=>{
    delete_location_by_id(location_id).then((response)=>{
      if(response.status==200){
        SuccessToast(response.data.message)
        getLocations(pageNumber)
      }
    }).catch((error)=>{
      console.log(error)
    })
  }
  const datas = () => {
    const result =
      locationListing &&
      locationListing.map((item, index) => {
        return {
          key: pageNumber * totalItemPerPage - (totalItemPerPage - (index + 1)),
          id:item.ld_id,
          name: item.ld_name,
          contact_person_name:item.ld_contact_person_name,
          email: item.ld_email_id,
          phone:item.ld_phone,
          address: item.ld_address,
          status:item.status_name,
        };
      });
    return result;
  };   
  const updatePagination=(pageNumber)=>{
    setPageNumber(pageNumber)
  }
  const updateItemPageCount=(pageItemCount)=>{
    setTotalItemPerPage(pageItemCount)
  }
  const searchfilter=(value)=>{
    setSearchFilter(value)
    if(value){
      getLocations(pageNumber,value)
    }else{
      getLocations(1,value)
      setPageNumber(1)
    }
    

  }
  return (
    <div className='locations_module_main_container'>
        <p className='locations_title'>Locations</p>
        <div className='locations_module_filter'>
          <LocationFilter searchFilter={searchfilter}/>
          <button className='locations_add_button' onClick={()=>{
            navigate(baseRouteUrl+'/create_new_location')
          }}>New</button>
        </div>
        <div className='locations_table_main_container'>
          <CustomTable columns={Columns} rows={datas()}/>
        </div>
        <div>
         <CustomBottomPagination total_items_per_page={totalItemPerPage} total_page_count={totalPages} current_page={pageNumber} on_change_value={updatePagination} on_page_count_update={updateItemPageCount}/>
      </div>
      </div>
  )
}

export default Locations