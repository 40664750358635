import React from 'react'
import './customSimpleTable.css'
function CustomSimpleTable({ columns, rows }) {
    return (
        <div className='custom_simple_main_container'>
            <table>
                <tr>
                    {columns &&
                        columns.map((column) => (
                            <th
                                key={column.key}
                            >
                                {column.title}
                            </th>
                        ))}
                </tr>
                {rows &&
                    rows.map((row) => (
                        <tr key={row.key} className='custom_simple_sub_rows_container'>
                            {columns.map((column) => (
                                <td
                                    key={column.key}
                                >
                                    {row[column.dataIndex] ? row[column.dataIndex] : " - "}
                                </td>
                            ))}
                        </tr>
                    ))}
            </table>
        </div>


    )
}

export default CustomSimpleTable