import React, { useEffect, useState } from 'react'
import './flightTicketDateChange.css'
import CustomTableColumnWithEditableRow from '../../../../../components/custom_table_column_with_editable_row/CustomTableColumnWithEditableRow'
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea'
import { MdOutlineDateRange } from "react-icons/md";
import FlightDateChangeModals from './flight_date_change_modals/FlightDateChangeModals'
import CustomDatePicker from '../../../../../components/custom_date_picker/CustomDatePicker';
import { ErrorToast } from '../../../../../toast/toasts';
import CustomModal from '../../../../../components/custom_modal/CustomModal';
import FlightDateChangeConfirm from './flight_date_change_confirm/FlightDateChangeConfirm';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IconButton } from '@mui/material';
function FlightTicketDateChange({ details,onClose }) {
    const [checkItems, setCheckedItems] = useState([])
    const [dateChangeModal, setDateChangeModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [formDetails, setFormDetails] = useState(null)
    const [cancelConfirmModal, setCancelConfirmModal] = useState(false)
    const [passengerCounts, setPassengerCounts] = useState({
        'adult': 0,
        'child': 0,
        'infants': 0
    })
    const [passengerDetails, setPassengerDetails] = useState([])
    const [dateChangePassengers, setDateChangePassengers] = useState([])
    const [basicDetails, setBasicDetails] = useState({
        remark: '',
        updated_on: ''
    })
    const [dateChangeFee, setDateChangeFee] = useState(0)
    useEffect(() => {
        passengerCount()
        setPassengerDetails(datas(details?.passanges))
    }, [details])

    useEffect(() => {
        const date_change_charge = dateChangePassengers?.reduce((prev, curr) => { return prev + curr.passenger_total_charge }, 0)
        setDateChangeFee(date_change_charge)
    }, [dateChangePassengers])

    const passengerCount = () => {
        let adultCount = 0;
        let childCount = 0;
        let infantCount = 0;
        let passengerNet = 0
        details?.passanges?.forEach((item) => {
            passengerNet = passengerNet + Number(item.FBPD_customer_net)
            if (item.FBPD_Passanger_type === 1) adultCount++;
            else if (item.FBPD_Passanger_type === 2) childCount++;
            else if (item.FBPD_Passanger_type === 3) infantCount++;

        });

        setPassengerCounts({
            adult: adultCount,
            child: childCount,
            infants: infantCount,
        });
        // console.log(passengerNet)
        // console.log('executed1')
        // setAmount({ ...amount, ['totalAmount']: passengerNet })
    }
    const Columns = [
        {
            title: "check",
            dataIndex: "header_checkbox",
            key: "header_checkbox",
            action: (row) => (
                <input type='checkbox' className='custom_check_box_color' checked={checkItems?.length == datas()?.length ? true : checkItems?.includes(row?.key)} onChange={() => {
                    if (Array.isArray(row)) {
                        if (checkItems.length == datas().length) {
                            setCheckedItems([])
                            setDateChangePassengers([])
                        } else {
                            setCheckedItems(row.map((item) => item.key))
                        }
                    } else {
                        if (checkItems.includes(row.key)) {
                            setCheckedItems(checkItems.filter((item) => item !== row.key))
                            const get_Passenger_details = dateChangePassengers.filter((item) => item.FDCD_passangerID != row.passenger_id)
                            setDateChangePassengers(get_Passenger_details)
                        } else {
                            setCheckedItems([...checkItems, row.key])
                        }
                    }
                }} />
            ),

        },
        {
            title: "#",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },

        {
            title: "Passenger Type",
            dataIndex: "passenger_type",
            key: "passenger_type",
        },
        {
            title: "Ticket Number",
            dataIndex: "ticket_number",
            key: "ticket_number",
        },
        {
            title: "Travel Date",
            dataIndex: "travel_date",
            key: "travel_date",
        },
        {
            title: "Return Date",
            dataIndex: "return_date",
            key: "return_date",
        },
        {
            title: "Supplier Amount",
            dataIndex: "supplier_amount",
            key: "supplier_amount",
        },
        {
            title: "Service Charge",
            dataIndex: "service_charge",
            key: "service_charge",
        },
        {
            title: "Customer Net",
            dataIndex: "customer_net",
            key: "customer_net",
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            action: (row) => (
                <div >

                    <MdOutlineDateRange className={checkItems.includes(row.key)?'flight_date_change_action':'flight_date_change_action_diabled'}  onClick={() => {
                        if (dateChangePassengers.length > 0) {
                            const Passenger_details = dateChangePassengers.find((item) => item.FDCD_passangerID == row.passenger_id)
                            if (Passenger_details) {
                                setSelectedItem({ ...Passenger_details, 'type': 'date_change_form' })
                            } else {
                                setSelectedItem({ ...row, 'type': 'date_change_form' })
                            }
                        } else {
                            setSelectedItem({ ...row, 'type': 'date_change_form' })
                        }
                        setDateChangeModal(true)
                    }} />
                </div>
            ),
        }
    ]
    const datas = () => {
        const result =
            details?.passanges &&
            details?.passanges.map((item, index) => {
                return {
                    key: index + 1,
                    id: item.FBPD_id,
                    name: item.FBPD_FullName,
                    passenger_type: item.FBPD_Passanger_type,
                    ticket_number: item.FBPD_ticket_number,
                    supplier_amount: item.FBPD_supplier_amount,
                    service_charge: item.FBPD_service_fee,
                    customer_net: item.FBPD_customer_net,
                    return_date: details.FBD_return_date?.split('T')[0],
                    travel_date: details.FBD_travel_date?.split('T')[0],
                    booking_id: item.FBPD_booking_id,
                    passenger_id: item.FBPD_id,
                    pnr_number:details?.FBD_PNR
                };
            });
        return result;
    };
    const handleModalClose = (is_close) => {
        setDateChangeModal(is_close)
        setSelectedItem(null)
    };

    const handleDateChangePassenger = (Passenger_details) => {
        const get_Passenger_details = dateChangePassengers.find((item) => item.FDCD_passangerID == Passenger_details.FDCD_passangerID)
        if (get_Passenger_details) {
            const modify_details = dateChangePassengers.map((item) => {
                return item.FDCD_passangerID == Passenger_details.passenger_id ? Passenger_details : item
            })
            setDateChangePassengers(modify_details)
        } else {
            setDateChangePassengers([...dateChangePassengers, Passenger_details])
        }
    }
    const onProceed = () => {
        if (checkItems.length === 0) {
            ErrorToast('Please select passenger')
        } else {
            const checkedPassengers=dateChangePassengers.filter((item)=>checkItems.includes(item.key))
            const passegerWithOtherDetails=passengerDetails.filter((item)=>checkItems.includes(item.key))
            if(passegerWithOtherDetails.length!==checkedPassengers.length) return ErrorToast('Passenger travel date is not set')
            setFormDetails({passengerData:checkedPassengers,passegerWithOtherDetails:passegerWithOtherDetails,basicDetails:{...basicDetails}, amount:dateChangeFee})
            setCancelConfirmModal(true)
        }

    }
    const handleCancelModalClose = (isclose) => {
        setCancelConfirmModal(isclose)
    }
    const handleCancelModalTotalClose = (isclose) => {
        onClose(isclose)
        setCancelConfirmModal(isclose)
    }
    return (
        <div>
            <CustomModal
            maxWidth={"1500px"}
            open={cancelConfirmModal}
            handleClose={handleCancelModalClose}
            close={<IconButton
                aria-label="close"
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
                onClick={() => {
                    handleCancelModalClose(false)
                }}
            > <IoIosCloseCircleOutline />
            </IconButton>}
            body={
                <FlightDateChangeConfirm
                    formDetails={formDetails}
                    handleCloseCancelConfirmModal={handleCancelModalClose}
                    handleCloseAllConfirmModal={handleCancelModalTotalClose}
                />
            }
            title={"Cancel Ticket"}
        />
            <div className='flight_ticket_date_change_main_container'>
                <p className="flight_ticket_date_change_legend">Passenger Details</p>
                <div >
                    <div className='flight_date_change_passenger_count'>
                        <p>Adult(s) : {passengerCounts.adult}</p>
                        <p>Child(s) : {passengerCounts.child}</p>
                        <p>Infants(s) : {passengerCounts.infants}</p>
                    </div>
                    <CustomTableColumnWithEditableRow columns={Columns} rows={passengerDetails} checkItems={checkItems} no_border={true} />
                </div>
            </div>
            <div className='flight_ticket_date_main_container'>
                <p className="flight_ticket_date_form_legend">Reshedule Details</p>
                <div className='flight_ticket_date_change_form_sub_container'>
                    <div >
                        <div className='flight_ticket_date_change_row_alignment' style={{ maxWidth: '500px' }}>
                            <p className='flight_ticket_date_change_label_text'>Updated On</p>
                            <CustomDatePicker
                                name="updated_on"
                                onChange={(e) => {
                                    setBasicDetails({ ...basicDetails, ['updated_on']: e })
                                }}
                            />
                        </div>
                        <div className='flight_ticket_date_change_row_alignment'>
                            <p className='flight_ticket_date_change_label_total_text'>No Of Passengers</p>
                            <p className='flight_ticket_date_change_label_total_text'>{checkItems.length}</p>
                        </div>
                        <div className='flight_ticket_date_change_row_alignment'>
                            <p className='flight_ticket_date_change_label_total_text'>Total Date Change Fee</p>
                            <p className='flight_ticket_date_change_label_total_text'>{dateChangeFee}</p>
                        </div>
                        <div className='flight_ticket_date_change_row_alignment'>
                            <p className='flight_ticket_date_change_label_total_text'>Total Payable Amount</p>
                            <p className='flight_ticket_date_change_label_total_text'>{dateChangeFee}</p>
                        </div>

                    </div>
                    <div>
                        <div className='flight_ticket_date_change_remark_container'>
                            <p className="flight_ticket_date_remark_legend">Remarks</p>
                            <CustomTextArea
                                noBorder={true}
                                rows={6}
                                name="narration"
                                placeholder={"Enter a remarks"}
                                onChange={(e) => {
                                    setBasicDetails({ ...basicDetails, ['remark']: e.target.value })
                                }}

                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flight_ticket_date_single_row_alignment'>
                <button className='flight_ticket_date_cancel_form_reset_button' onClick={() => {
                    onClose(false)
                }} >Close</button>
                <button
                    className='flight_ticket_date_cancel_form_save_button' onClick={() => {
                        onProceed()
                    }}>Proceed</button>
            </div>
            <FlightDateChangeModals details={selectedItem} open={dateChangeModal} onClose={handleModalClose} handleDateChangePassenger={handleDateChangePassenger} />
        </div>

    )
}

export default FlightTicketDateChange