import React, { useEffect, useState } from 'react'
import './suppliersStyle.css'
import { useNavigate } from 'react-router-dom'
import CustomTable from '../../../../components/custom_table/CustomTable'
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { delete_supplier, get_supplier_with_pagination } from '../../../../api/supplierApis';
import CustomBottomPagination from '../../../../components/custom_bottom_pagination/CustomBottomPagination';
import { ErrorToast, SuccessToast } from '../../../../toast/toasts';
import { PiEye } from "react-icons/pi";
import SupplierFilter from './supplier_filter/SupplierFilter';
import { LocalStorageServices } from '../../../../services/localStorageServices';

function Suppliers() {
     const baseRouteUrl=`/ecrs_portal/${LocalStorageServices.getUserRole()}/master_settings`
    const navigate=useNavigate()
    const [supplierListing,setSupplierListing]=useState([])
    const [pageNumber,setPageNumber]=useState(1)
    const [totalPages,setTotalPages]=useState(0)
    const [totalItemPerPage,setTotalItemPerPage]=useState(10)
    const [searchFilter,setSearchFilter]=useState(null)
    useEffect(() => {
      get_suppliers(pageNumber,searchFilter)
    }, [pageNumber,totalItemPerPage])

    const get_suppliers=(pageNumber,filterValue)=>{
      const formData = new FormData();
      formData.append('page', pageNumber);
      formData.append('pageItem_count', totalItemPerPage);
      if(filterValue){
        for (const key in filterValue) {
          formData.append(key, filterValue[key]);
      }
      }
      get_supplier_with_pagination(formData).then((response) => {
        setSupplierListing(response.data.data)
        setTotalPages(response.data.total_pages)
      }).catch((error) => {
        let message = "";
        for (const key in error.data.errors) {
          if (error.data.errors.hasOwnProperty(key)) {
            const keyMessages = error.data.errors[key].map(message => message);
            message = message.concat(keyMessages);
          }
        }
        ErrorToast(message)
      })
    }
    const Columns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key",
        
      },
      {
        title: "Supplier Name",
        dataIndex: "supplier",
        key: "supplier",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Phone Number",
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        wrapper: (row) => (
          <div >
            <p style={{color:row.status=='Active'?'#0A9E02':"#FF0000"}}>{row.status}</p>
          </div>
        ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        action: (row) => (
          <div style={{display:'flex',alignItems:'center','gap':5}}>
             <PiEye className='supplier_action_view' onClick={()=>{
             navigate(baseRouteUrl+`/${row.id}/view_supplier`)
           }}/> 
            <BiEditAlt className='supplier_action_edit' onClick={()=>{
              navigate(baseRouteUrl+`/${row.id}/update_supplier`)
            }}  />
            <MdOutlineDeleteOutline className='supplier_action_delete' onClick={()=>{
              deleteSupplier(row.id)
            }}/>
          </div>
        ),
      },
    ];
    const updatePagination=(pageNumber)=>{
      setPageNumber(pageNumber)
    }
    const updateItemPageCount=(pageItemCount)=>{
      setTotalItemPerPage(pageItemCount)
    }
    const deleteSupplier=(supplier_id)=>{
      delete_supplier(supplier_id).then((response)=>{
        if(response.status==200){
          SuccessToast(response.data.message)
          get_suppliers(pageNumber)
        }
      }).catch((error)=>{
        console.log(error)
      })
    }
    const datas = () => {
      const result =
        supplierListing &&
        supplierListing.map((item, index) => {
          return {
            key:pageNumber * totalItemPerPage - (totalItemPerPage - (index + 1)),
            id:item.SD_id,
            supplier:item.SD_name,
            email:item.SD_email,
            phone:item.SD_phone,
            status:item.status_name,
          };
        });
      return result;
    };
    const searchfilter=(value)=>{
      setSearchFilter(value)
      if(value){
        get_suppliers(pageNumber,value)
      }else{
        get_suppliers(1,value)
        setPageNumber(1)
      }
      
  
    }
    return (
      <div className='suppliers_module_main_container'>
        {/* <Dummy/> */}
        <p className='suppliers_title'>Supplier</p>
        <div className='supplier_module_filter'>
          <SupplierFilter searchFilter={searchfilter} />
          <button className='supplier_add_button' onClick={()=>{
            navigate(baseRouteUrl+`/create_new_supplier`)
          }}>New</button>
        </div>
        <div className='supplier_table_main_container'>
          <CustomTable columns={Columns} rows={datas()}/>
        </div>
        <div>
         <CustomBottomPagination total_items_per_page={totalItemPerPage} total_page_count={totalPages} current_page={pageNumber} on_change_value={updatePagination} on_page_count_update={updateItemPageCount}/>
      </div>
      </div>
    )
}

export default Suppliers