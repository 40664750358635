import React from 'react'
import CustomModal from '../../../../../components/custom_modal/CustomModal'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea'
import { CommonHelpers } from '../../../../../services/commonHelpers'
import { RiDownloadLine } from "react-icons/ri";
import './flightTicketInvoiceDetailsModal.css'
function FlightTicketInvoiceDetailsModal({ selected_item, open_modal, modal_close }) {
  return (
    <div>
    <CustomModal
        open={open_modal}
        handleClose={(e)=>{
            modal_close(e)
        }}
        body={
            <div>
                <div>
                    <p className='financial_level_invoice_modal_label'>Invoice Number</p>
                    <CustomTextField isDisabled={true} value={selected_item?.FBD_invoice_number}
                    />
                </div>
                <div>
                    <p className='financial_level_invoice_modal_label'>Comment</p>
                    <CustomTextArea isDisabled={true} rows={3} value={selected_item?.billingdoc[0]?.comments[0]}/>
                </div>

                <div>
                    <p className='financial_level_invoice_modal_label'>Attachments</p>
                    {selected_item?.billingdoc && selected_item?.billingdoc?.length > 0 &&  selected_item?.billingdoc?.map((item, index) => (
                        <div className='financial_level_invoice_info_attchment_item'>
                            <p>{item.FBBD_file ? item.FBBD_file : ''}</p>
                            <div>
                                <RiDownloadLine className='financial_level_invoice_download_action'  onClick={() => {
                                    if (item.file_url) {
                                        CommonHelpers.DownloadAssets(item.file_url)
                                    }
                                }} />
                            </div>
                        </div>
                    ))}

                </div>

                <div className='financial_level_invoice_modal_form_single_row_alignment'>
                    <button className='financial_level_invoice_modal_form_reset_button' onClick={() => {
                        modal_close(false)
                    }}>Cancel</button>

                </div>
            </div>
        }
        title={"Invoice Details"}
    />
</div>
  )
}

export default FlightTicketInvoiceDetailsModal