import React from 'react'
import las_logo from '../../../../assets/las_logo.png'
import m2_logo from '../../../../assets/m2_logo.png'
import './loginFormStyle.css'
import { useNavigate } from 'react-router-dom'
import CustomTextField from '../../../../components/custom_text_field/CustomTextField'
import * as Yup from "yup";
import { useFormik } from 'formik'
import { user_login } from '../../../../api/authApis'
import { ErrorToast } from '../../../../toast/toasts'
import { LocalStorageServices } from '../../../../services/localStorageServices'
function LoginForm() {
    const navigate = useNavigate()
    const formik = useFormik({
        initialValues: {
            "username": "",
            "password": "",
        },
        validationSchema: Yup.object({
            username: Yup.string().required("User name is required"),
            password: Yup.string().required("Password is required"),
        }),
        onSubmit: (values) => {
            const formData = new FormData();
            for (const key in values) {
                formData.append(key, values[key]);
            }
            user_login(formData).then((response)=>{
                if(response.status==200){
                    LocalStorageServices.setAccessTokenLocalStorageData(response.data.access_token)
                    LocalStorageServices.setUserDetailsLocalStorageData(JSON.stringify(response.data.user))
                    navigate(`/ecrs_portal/${response.data.user.role.role_name}/dashboard`)
                }
            }).catch((error)=>{
                ErrorToast(error?.data?.message)
            })
        }
    })
    return (
        <div className='login_form_main_container'>
            <div className='login_form_sub_container_1'>
                <img src={las_logo} className='login_form_las_logo' />
                <img src={m2_logo} className='login_form_m2_logo' />
            </div>
            <div className='login_form_sub_container_2'>
                <h5 className='login_form_heading_title'>Hello Again!</h5>
                <p className='login_form_sub_title'>Login to grab your next oppertunity</p>
            </div>
            <form id="loginform" onSubmit={formik.handleSubmit}>
                <div className='login_form_fields_labels_container'>
                    <div>
                        <p className='form_label'>User Name</p>
                        <CustomTextField
                            name="username"
                            placeholder={"Enter username"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.username}
                            error={formik.touched.username &&
                                Boolean(formik.errors.username)
                            }
                            helperText={
                                formik.touched.username && formik.errors.username
                            }
                        />
                    </div>
                    <div>
                        <p className='form_label'>Password</p>
                        <CustomTextField
                            name="password"
                            type="password"
                            placeholder={"Enter password"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            error={formik.touched.password &&
                                Boolean(formik.errors.password)
                            }
                            helperText={
                                formik.touched.password && formik.errors.password
                            }
                        />

                    </div>
                    <div className='login_form_remember_forget_container'>
                        <div className='login_form_remember_me_container'>
                            <input placeholder='Password' type="checkbox" />
                            <p className='login_form_remember_me_text'>Remember me</p>
                        </div>
                        <div>
                            <p className='login_form_forget_text'>Forget password ?</p>
                        </div>
                    </div>
                    <button className='login_form_sign_up_container'
                        type="submit"
                        form="loginform"> Sign In </button>
                </div>
            </form>

        </div>
    )
}

export default LoginForm