import React, { useEffect } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField';
import { IoArrowBackSharp } from "react-icons/io5";
import dayjs from "dayjs";
import './flightTicketFormStyle.css'
import CustomDatePicker from '../../../../../components/custom_date_picker/CustomDatePicker';
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown';
import FlightTicketPassengerTable from '../flight_ticket_passenger_table/FlightTicketPassengerTable';
import FlightTicketAdditionalInfo from '../flight_ticket_additional_info/FlightTicketAdditionalInfo';
import { get_supplier_without_pagination } from '../../../../../api/supplierApis';
import { useState } from 'react';
import { get_client_lists_without_pagination } from '../../../../../api/clientApis';
import { get_ticket_type, get_trip_type } from '../../../../../api/commonLookUpApis';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { booking_document_delete, create_new_flight_booking, get_flight_ticket_details_by_id, update_flight_booking, upload_document_for_booking } from '../../../../../api/process_ecr/flightTicketApis';
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts';
function FlightTicketForm() {
    const navigate = useNavigate()
    let { booking_id } = useParams();
    const location = useLocation()
    const [supplier, setSupplier] = useState([])
    const [client, setClient] = useState([])
    const [ticketType, setTicketType] = useState([])
    const [tripType, setTripType] = useState([])
    const [passengerList, setPassengerList] = useState([])
    const [fileAttachments, setFileAttachments] = useState([])
    const [isSaveAsDraft, setIsSaveAsDraft] = useState(true)
    const [passengerCount, setPassengerCount] = useState(0)
    const [passengerOtherCount, setPassengerOtherCount] = useState({
        'adult': 0,
        'child': 0,
        'infants': 0
    })
    const [deletedAttachments, setDeletedAttachments] = useState([])
    const [isUpdated, setIsUpdated] = useState(false)
    const [isView, setIsView] = useState(null)
    // const [reviewHistory,setReviewHistory]=useState([])
    useEffect(() => {
        if (location.pathname.split('/').pop() === "view_flight_ticket") {
            setIsView(true)
        }
        getSupplierList()
        getClientList()
        getTicketTypeList()
        getTripTypeList()
        if (booking_id) {
            const formData = new FormData();
            formData.append('bookingId', booking_id);
            get_flight_ticket_details_by_id(formData).then((response) => {
                const booking_datas = response?.data?.booking
                const passenger_datas = response?.data?.passengers
                const passenger_documents = response?.data?.documents
                const review_history = response?.data?.reviewHistory
                formik.setValues({
                    ...formik.values,
                    'FBD_PNR': booking_datas.FBD_PNR,
                    'FBD_ticket_type_id': booking_datas.FBD_ticket_type_id,
                    'FBD_trip_type': booking_datas.FBD_trip_type,
                    'FBD_FlightName':booking_datas.FBD_FlightName,
                    'FBD_issue_date': booking_datas.FBD_issue_date ? booking_datas.FBD_issue_date.split('T')[0] : booking_datas.FBD_issue_date,
                    'FBD_travel_date': booking_datas.FBD_travel_date ? booking_datas.FBD_travel_date.split('T')[0] : booking_datas.FBD_travel_date,
                    'FBD_return_date': booking_datas.FBD_return_date ? booking_datas.FBD_return_date.split('T')[0] : booking_datas.FBD_return_date,
                    'FBD_supplier_id': booking_datas.FBD_supplier_id,
                    'FBD_client_id': booking_datas.FBD_client_id,
                    'FBD_sector': booking_datas.FBD_sector,
                    'FBD_reference': booking_datas.FBD_reference,
                    'FBD_adult': booking_datas.FBD_adult,
                    'FBD_child': booking_datas.FBD_child,
                    'FBD_infant': booking_datas.FBD_infant,
                    'FBD_pax_count': booking_datas.FBD_pax_count,
                    'FBD_comment': booking_datas.FBD_comment
                })
                setPassengerCount(booking_datas.FBD_pax_count)
                setFileAttachments(passenger_documents)
                setPassengerList(passenger_datas)
                // setReviewHistory(review_history)
                setPassengerOtherCount({
                    'adult': booking_datas.FBD_adult,
                    'child': booking_datas.FBD_child,
                    'infants': booking_datas.FBD_infant
                })
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [isUpdated])

    const getSupplierList = () => {
        get_supplier_without_pagination().then((response) => {
            const data = response?.data?.data.map((item) => {
                return {
                    label: item.SD_name,
                    value: item.SD_id,
                };
            });
            setSupplier(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const getClientList = () => {
        get_client_lists_without_pagination().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.CI_name,
                    value: item.CI_id,
                };
            });
            setClient(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const getTicketTypeList = () => {
        get_ticket_type().then((response) => {
            const data = response?.data?.data?.map((item) => {
                return {
                    label: item.TT_type,
                    value: item.TT_id,
                };
            });
            setTicketType(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getTripTypeList = () => {
        get_trip_type().then((response) => {
            const data = response?.data?.data?.map((item) => {
                return {
                    label: item.TRIP_name,
                    value: item.TRIP_id,
                };
            });
            setTripType(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const set_passenger_list = (item) => {
        setPassengerList([...passengerList, item])
    }
    const update_passenger_list = (item) => {
        passengerList.splice(item.index, 1, item)
        setPassengerList(passengerList)
    }
    const delete_particular_passenger_details = (index) => {
        passengerList.splice(index, 1)
        setPassengerList([...passengerList])
    }

    const set_attachment_list = (item) => {
        setFileAttachments([...fileAttachments, item])
    }
    const delete_particular_attachment_details = (index, item) => {
        fileAttachments.splice(index, 1)
        setFileAttachments([...fileAttachments])
        if (item.FBDF_id) {
            setDeletedAttachments([...deletedAttachments, item.FBDF_id])
        }
    }
    const formik = useFormik({
        initialValues: {
            'FBD_PNR': '',
            'FBD_ticket_type_id': '',
            'FBD_trip_type': '',
            'FBD_FlightName':'',
            'FBD_issue_date': null,
            'FBD_travel_date': null,
            'FBD_return_date': null,
            'FBD_supplier_id': '',
            'FBD_client_id': '',
            'FBD_sector': '',
            'FBD_reference': '',
            'FBD_adult': 0,
            'FBD_child': 0,
            'FBD_infant': 0,
            'FBD_pax_count': 0,
            'FBD_comment': ''
        },
        validationSchema: Yup.object({
            FBD_PNR: Yup.string().required("Pnr number is required"),
            FBD_FlightName:Yup.string().required("Airline name is required"),
            FBD_ticket_type_id: Yup.string().required("Ticket type is required"),
            FBD_trip_type: Yup.string().required("Trip type is required"),
            FBD_issue_date: Yup.string().required("Issue date is required"),
            FBD_travel_date: Yup.string().required("Travel date is required"),
            FBD_supplier_id: Yup.string().required("Supplier is required"),
            FBD_client_id: Yup.string().required("Client/Customer is required"),
            FBD_sector: Yup.string().required("Sector is required"),
            FBD_adult: Yup.number()
                .required("Adult count is required")
                .min(0, "Adult count cannot be less than 0")
                .max(9, "Adult count cannot be more than 9"),
            FBD_child: Yup.number()
                .required("child count is required")
                .min(0, "child count cannot be less than 0")
                .max(9, "child count cannot be more than 9"),
            FBD_infant: Yup.number()
                .required("Infant count is required")
                .min(0, "Infant count cannot be less than 0")
                .test(
                    'infant-less-than-adult',
                    'Infant count cannot be greater than adult count',
                    function (value) {
                        return value <= this.parent.FBD_adult;
                    }
                ),
            FBD_pax_count: Yup.number()
                .required("Passenger count is required")
                .min(0, "Passenger count cannot be less than 0")
                .max(9, "Passenger count cannot be more than 9"),
            FBD_return_date: Yup.string()
                .when('FBD_trip_type', {
                    is: "2",
                    then: (schema) =>
                        schema.nullable(false).required('Return date is required'),
                    otherwise: (schema) => schema.nullable()
                })
        }),

        onSubmit: (values) => {
            let value = JSON.parse(JSON.stringify(values))
            value.FBD_ECRstatusID = isSaveAsDraft ? 1 : 2
            value.passengerData = passengerList
            // value.fileData=[]
            if (!booking_id) {
                create_new_flight_booking(value).then((response) => {
                    if (response.status == 200) {
                        const attachments = fileAttachments.map((item) => item.file)
                        if (attachments.length > 0) {
                            const formData = new FormData();
                            attachments.map((item, index) => {
                                formData.append(`file[${index}]`, item);
                            })
                            formData.append('bookingId', response?.data?.bookingId);
                            upload_document_for_booking(formData).then((response_1) => {
                                console.log(response_1)
                            }).catch((error) => {
                                console.log(error)
                            })
                        }
                        navigate(-1)
                        SuccessToast(response.data.message)
                        setIsUpdated(!isUpdated)
                    }
                }).catch((error) => {
                    let message = "";
                    for (const key in error?.data?.errors) {
                        if (error.data.errors.hasOwnProperty(key)) {
                            const keyMessages = error.data.errors[key].map(message => message);
                            message = message.concat(keyMessages);
                        }
                    }
                    ErrorToast(message)
                })
            } else {
                if (deletedAttachments) {
                    const formData = new FormData();
                    formData.append('bookingId', booking_id);
                    deletedAttachments.map((item) => {
                        formData.append('BookingDocumentid', item);
                        booking_document_delete(formData).then((response_1) => {
                            console.log(response_1)
                        }).catch((error) => {
                            console.log(error)
                        })
                    })
                }
                update_flight_booking(booking_id, value).then((response) => {
                    if (response.status == 200) {

                        const attachments = fileAttachments.filter((item) => item.file).map((item) => item.file)
                        if (attachments.length > 0) {
                            const formData = new FormData();
                            attachments.map((item, index) => {
                                formData.append(`file[${index}]`, item);
                            })
                            formData.append('bookingId', booking_id);
                            upload_document_for_booking(formData).then((response_1) => {
                                console.log(response_1)
                            }).catch((error) => {
                                console.log(error)
                            })
                        }
                        SuccessToast(response.data.message)
                        if (value.FBD_ECRstatusID == 2) {
                            navigate(-1)
                        }
                        setIsUpdated(!isUpdated)

                    }
                }).catch((error) => {
                    let message = "";
                    for (const key in error?.data?.errors) {
                        if (error.data.errors.hasOwnProperty(key)) {
                            const keyMessages = error.data.errors[key].map(message => message);
                            message = message.concat(keyMessages);
                        }
                    }
                    ErrorToast(message)
                })
            }

        }
    })
    const resetState = () => {
        setPassengerList([])
        setFileAttachments([])
        setPassengerCount(0)
        setDeletedAttachments([])
    }



    return (
        <div className='flight_ticket_form_super_container'>
            <div >
                <div className='flight_ticket_form_sub_container'>
                    <div className='flight_ticket_form_single_row_alignment'>
                        <IoArrowBackSharp className='flight_ticket_form_back' onClick={() => {
                            navigate(-1)
                        }} />
                        <p className='flight_ticket_form_title'>{booking_id && isView ? 'View Booking Details' : booking_id ? 'Update Booking Details' : 'Book Flight Ticket'}</p>
                    </div>
                    {!isView && (
                        <div className='flight_ticket_form_single_row_alignment'>
                            {!booking_id ? <button className='flight_ticket_form_reset_button' onClick={() => {
                                formik.resetForm()
                                resetState()
                            }}>Reset</button> : <></>}
                            <button
                                className='flight_ticket_form_save_button'
                                type="submit"
                                form="flightTicketForm" onClick={() => {
                                    setIsSaveAsDraft(true)
                                }}>Save as Draft</button>
                                {passengerList.length>0 ?  <button
                                className='flight_ticket_form_save_button'
                                type="submit"
                                form="flightTicketForm" onClick={() => {
                                    setIsSaveAsDraft(false)
                                }}>Submit</button>:<></>}
                        </div>
                    )}



                </div>

                <div>
                    <form id="flightTicketForm" onSubmit={formik.handleSubmit}>
                        <div className='flight_ticket_form_main_container'>
                            <p className="flight_ticket_form_legend">General Info</p>
                            <div className='flight_ticket_form_sub_container_0'>
                                <div>
                                    <p className='flight_ticket_form_label'>PNR</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="FBD_PNR"
                                        placeholder={"Enter pnr number"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.FBD_PNR}
                                        error={formik.touched.FBD_PNR &&
                                            Boolean(formik.errors.FBD_PNR)
                                        }
                                        helperText={
                                            formik.touched.FBD_PNR && formik.errors.FBD_PNR
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Airline Name</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="FBD_FlightName"
                                        placeholder={"Enter Airline name"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.FBD_FlightName}
                                        error={formik.touched.FBD_FlightName &&
                                            Boolean(formik.errors.FBD_FlightName)
                                        }
                                        helperText={
                                            formik.touched.FBD_FlightName && formik.errors.FBD_FlightName
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Issue Date</p>
                                    <div style={{ width: "100%" }}>
                                        <CustomDatePicker
                                            disabled={isView}
                                            name="FBD_issue_date"
                                            onChange={(date) => {
                                                formik.setFieldValue("FBD_issue_date", date);
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={dayjs(formik.values.FBD_issue_date, "DD-MM-YYYY")}
                                            error={
                                                formik.touched.FBD_issue_date &&
                                                Boolean(formik.errors.FBD_issue_date)
                                            }
                                            helperText={
                                                formik.touched.FBD_issue_date && formik.errors.FBD_issue_date
                                            } />

                                    </div>

                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Ticket Type</p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="FBD_ticket_type_id"
                                        placeholder={"Select a ticket type"}
                                        options={ticketType}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "FBD_ticket_type_id",
                                                newValue ? newValue.value : ""
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            ticketType.find((type) =>
                                                type.value ===
                                                formik.values.FBD_ticket_type_id
                                            ) || null
                                        }
                                        error={
                                            formik.touched.FBD_ticket_type_id &&
                                            Boolean(formik.errors.FBD_ticket_type_id)
                                        }
                                        helperText={
                                            formik.touched.FBD_ticket_type_id &&
                                            formik.errors.FBD_ticket_type_id
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Customer/Client</p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="FBD_client_id"
                                        placeholder={"Select a customer"}
                                        options={client}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "FBD_client_id",
                                                newValue ? newValue.value : ""
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            client.find((type) =>
                                                type.value ===
                                                formik.values.FBD_client_id
                                            ) || null
                                        }
                                        error={
                                            formik.touched.FBD_client_id &&
                                            Boolean(formik.errors.FBD_client_id)
                                        }
                                        helperText={
                                            formik.touched.FBD_client_id &&
                                            formik.errors.FBD_client_id
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Supplier</p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="FBD_supplier_id"
                                        placeholder={"Select a supplier"}
                                        options={supplier}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "FBD_supplier_id",
                                                newValue ? newValue.value : ""
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            supplier.find((type) =>
                                                type.value ===
                                                formik.values.FBD_supplier_id
                                            ) || null
                                        }
                                        error={
                                            formik.touched.FBD_supplier_id &&
                                            Boolean(formik.errors.FBD_supplier_id)
                                        }
                                        helperText={
                                            formik.touched.FBD_supplier_id &&
                                            formik.errors.FBD_supplier_id
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Sector</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="FBD_sector"
                                        placeholder={"Enter sector"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.FBD_sector}
                                        error={formik.touched.FBD_sector &&
                                            Boolean(formik.errors.FBD_sector)
                                        }
                                        helperText={
                                            formik.touched.FBD_sector && formik.errors.FBD_sector
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Trip Type</p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="FBD_trip_type"
                                        placeholder={"Select a trip type"}
                                        options={tripType}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "FBD_trip_type",
                                                newValue ? newValue.value : ""
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            tripType.find((type) =>
                                                type.value ===
                                                formik.values.FBD_trip_type
                                            ) || null
                                        }
                                        error={
                                            formik.touched.FBD_trip_type &&
                                            Boolean(formik.errors.FBD_trip_type)
                                        }
                                        helperText={
                                            formik.touched.FBD_trip_type &&
                                            formik.errors.FBD_trip_type
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Travel Date</p>
                                    <div style={{ width: "100%" }}>
                                        <CustomDatePicker
                                            disabled={isView}
                                           
                                            name="FBD_travel_date"
                                            onChange={(date) => {
                                                formik.setFieldValue("FBD_travel_date", date);
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={dayjs(formik.values.FBD_travel_date, "DD-MM-YYYY")}
                                            error={
                                                formik.touched.FBD_travel_date &&
                                                Boolean(formik.errors.FBD_travel_date)
                                            }
                                            helperText={
                                                formik.touched.FBD_travel_date && formik.errors.FBD_travel_date
                                            } />

                                    </div>

                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Return Date</p>
                                    <div style={{ width: "100%" }}>
                                        <CustomDatePicker
                                            disabled={isView?isView:formik.values.FBD_travel_date?false:true}
                                            minDate={dayjs(formik.values.FBD_travel_date, "DD-MM-YYYY")} 
                                            name="FBD_return_date"
                                            onChange={(date) => {
                                                formik.setFieldValue("FBD_return_date", date);
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={dayjs(formik.values.FBD_return_date, "DD-MM-YYYY")}
                                            error={
                                                formik.touched.FBD_return_date &&
                                                Boolean(formik.errors.FBD_return_date)
                                            }
                                            helperText={
                                                formik.touched.FBD_return_date && formik.errors.FBD_return_date
                                            } />

                                    </div>

                                </div>
                                <div>
                                    <p className='flight_ticket_form_label'>Adult</p>
                                    <CustomTextField
                                        type={'number'}
                                        isDisabled={isView}
                                        name="FBD_adult"
                                        placeholder={"Enter adult number"}
                                        onChange={(e) => {
                                            formik.handleChange(e)
                                            if (e.target.value !== '') {
                                                const amount = Number(e.target.value) + formik.values.FBD_child +
                                                    formik.values.FBD_infant
                                                formik.setFieldValue("FBD_pax_count", amount);
                                                setPassengerCount(amount)
                                                setPassengerOtherCount({ ...passengerOtherCount, ['adult']: Number(e.target.value) })
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.FBD_adult}
                                        error={formik.touched.FBD_adult &&
                                            Boolean(formik.errors.FBD_adult)
                                        }
                                        inputProps={{ inputProps: { min: 0, max: 10 } }}
                                        helperText={
                                            formik.touched.FBD_adult && formik.errors.FBD_adult
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Child</p>
                                    <CustomTextField
                                        type={'number'}
                                        isDisabled={isView}
                                        name="FBD_child"
                                        placeholder={"Enter child number"}
                                        onChange={(e) => {
                                            formik.handleChange(e)
                                            if (e.target.value !== '') {
                                                const amount = Number(e.target.value) + formik.values.FBD_adult +
                                                    formik.values.FBD_infant
                                                formik.setFieldValue("FBD_pax_count", amount);
                                                setPassengerCount(amount)
                                                setPassengerOtherCount({ ...passengerOtherCount, ['child']: Number(e.target.value) })
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.FBD_child}
                                        error={formik.touched.FBD_child &&
                                            Boolean(formik.errors.FBD_child)
                                        }
                                        inputProps={{ inputProps: { min: 0, max: 10 } }}
                                        helperText={
                                            formik.touched.FBD_child && formik.errors.FBD_child
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Infants</p>
                                    <CustomTextField
                                        type={'number'}
                                        isDisabled={isView}
                                        name="FBD_infant"
                                        placeholder={"Enter infants number"}
                                        onChange={(e) => {
                                            formik.handleChange(e)
                                            if (e.target.value !== '') {
                                                const amount = Number(e.target.value) + formik.values.FBD_adult +
                                                    formik.values.FBD_child
                                                formik.setFieldValue("FBD_pax_count", amount);
                                                setPassengerCount(amount)
                                                setPassengerOtherCount({ ...passengerOtherCount, ['infants']: Number(e.target.value) })
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.FBD_infant}
                                        error={formik.touched.FBD_infant &&
                                            Boolean(formik.errors.FBD_infant)
                                        }
                                        inputProps={{ inputProps: { min: 0, max: 10 } }}
                                        helperText={
                                            formik.touched.FBD_infant && formik.errors.FBD_infant
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>PAX Count</p>
                                    <CustomTextField
                                        isDisabled={true}
                                        name="FBD_pax_count"
                                        placeholder={"pax count"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.FBD_pax_count}
                                        error={formik.touched.FBD_pax_count &&
                                            Boolean(formik.errors.FBD_pax_count)
                                        }
                                        helperText={
                                            formik.touched.FBD_pax_count && formik.errors.FBD_pax_count
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='flight_ticket_form_label'>Reference</p>
                                    <CustomTextField
                                        name="FBD_reference"
                                        isDisabled={isView}
                                        placeholder={"Enter reference"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.FBD_reference}
                                        error={formik.touched.FBD_reference &&
                                            Boolean(formik.errors.FBD_reference)
                                        }
                                        helperText={
                                            formik.touched.FBD_reference && formik.errors.FBD_reference
                                        }
                                    />
                                </div>

                                {/* <div>
                                    <p className='flight_ticket_form_label'>Flight Status</p>
                                    <CustomAutoCompleteDropdown
                                        // isDisabled={isView}
                                        name="flight_status"
                                        placeholder={"Select a flight_status"}
                                        options={[]}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "flight_status",
                                                newValue ? newValue.value : ""
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            [].find((type) =>
                                                type.value ===
                                                formik.values.flight_status
                                            ) || null
                                        }
                                        error={
                                            formik.touched.flight_status &&
                                            Boolean(formik.errors.flight_status)
                                        }
                                        helperText={
                                            formik.touched.flight_status &&
                                            formik.errors.flight_status
                                        }
                                    />
                                </div> */}
                            </div>
                        </div>

                        <div className='flight_ticket_form_main_container'>
                            <p className="flight_ticket_form_legend">Passenger Info</p>
                            <FlightTicketPassengerTable passengerCount={passengerCount} passengerOtherCount={passengerOtherCount} passengerList={passengerList} setPassengerList={set_passenger_list} updatePassengerDetails={update_passenger_list} passengerDelete={delete_particular_passenger_details} />
                        </div>

                        <div className='flight_ticket_form_main_container'>
                            <p className="flight_ticket_form_legend">Fair Info</p>
                            <div className='flight_ticket_form_fair_main_container'>
                                <p className='flight_ticket_form_fair_text'>Airline/Supplier Amount : {passengerList?.reduce((prev, current) => prev + (current.supplier_amount ? current.supplier_amount : Number(current.FBPD_supplier_amount)), 0)}</p>
                                <p className='flight_ticket_form_fair_text'>Service Fee : {passengerList?.reduce((prev, current) => prev + (current.service_fee ? current.service_fee : Number(current.FBPD_service_fee)), 0)}</p>
                                <p className='flight_ticket_form_fair_text'>Customer Net : {passengerList?.reduce((prev, current) => prev + (current.customer_net ? current.customer_net : Number(current.FBPD_customer_net)), 0)}</p>
                            </div>
                        </div>

                        <div className='flight_ticket_form_main_container'>
                            <p className="flight_ticket_form_legend">Additional Information</p>
                            <FlightTicketAdditionalInfo formik={formik} attachmnetList={fileAttachments} setAttachmentList={set_attachment_list} attachmentDelete={delete_particular_attachment_details} />
                        </div>

                        {/* {reviewHistory && reviewHistory.length>0 && (
                            <div className='flight_ticket_form_main_container'>
                            <p className="flight_ticket_form_legend">Review History</p>
                            <div className='flight_ticket_form_review_history_main_container'>
                               {reviewHistory.map((item)=>(
                                <div className='flight_ticket_form_review_history_sub_container'>
                                    <p className='flight_ticket_form_review_history_date'>{item.FBRH_review_date}</p>
                                    <p className='flight_ticket_form_review_history_text'>{item.FBRH_review_comment}</p>
                                </div>
                               ))}
                            </div>
                        </div>
                        )} */}
                    </form>

                </div>
            </div>
        </div>
    )
}

export default FlightTicketForm