import React, { useState } from 'react'
import './sliderMenuStyle.css'
import las_logo from '../../../assets/las_logo_white.png'
import SliderMenuComponent from '../../../components/slider_menu_component/SliderMenuComponent'
import DashboardLogo from '../../../assets/dashboard_logo.png'
import ProcessECR from '../../../assets/process_ecr_logo.png'
import Payments from '../../../assets/payment_icon.png'
import Address_book from '../../../assets/address_book_icon.png'
import UserManagement from '../../../assets/User_management_icon.png'
import Reports_icon from '../../../assets/reports_icon.png'
import Settings_icon from '../../../assets/setting_icon.png'
import Las_logo_mobile from '../../../assets/las_logo_only.png'
import { useSelector } from 'react-redux'
import { LocalStorageServices } from '../../../services/localStorageServices'
function SliderMenu() {
  let baseRouteUrl = '/ecrs_portal/:role/'
  useState(() => {
    const user_details = LocalStorageServices.getUserDetailsLocalStorageData()
    let ParsedUserDetails = user_details ? JSON.parse(user_details) : user_details
    if (ParsedUserDetails) {
      const newUrl = baseRouteUrl.replace(':role', ParsedUserDetails.role.role_name)
      baseRouteUrl = newUrl
    }

  }, [])
  const isCollapsed = useSelector((state) => state.commonStore.isCollapsed)
  return (
    <div className='home_page_slider_menus_main_container' style={{ width: isCollapsed ? 75 : 250, overflow: isCollapsed ? 'visible' : 'auto' }}>
      <div className='home_page_slider_sub_image_container' >
        {isCollapsed ? <img src={Las_logo_mobile} className='slider_las_collapsed_icon' /> : <img src={las_logo} className='slider_las_icon' />}
      </div>
      <div className='home_page_menu_container'>
        <SliderMenuComponent icon={DashboardLogo} text={'Dashboard'} route={baseRouteUrl + 'dashboard'} />
        <SliderMenuComponent icon={ProcessECR} text={'Process ECR'}
          subroute={[
            { text: "Flight Ticket", route: baseRouteUrl + 'process_ecr/flight_ticket', otherRoutes: ['create_new_flight_ticket', 'view_flight_ticket', 'update_flight_ticket'] },
            { text: "Packages", route: baseRouteUrl + 'process_ecr/packages' },
            { text: "Cabs", route: baseRouteUrl + 'process_ecr/cabs' },
            { text: "Hotel/Resorts", route: baseRouteUrl + 'process_ecr/hotels_or_resorts' },
            { text: "Train", route: baseRouteUrl + 'process_ecr/train' },
            { text: "Visa", route: baseRouteUrl + 'process_ecr/visa' },
            { text: "Travel Insurance", route: baseRouteUrl + 'process_ecr/travel_insurance' },
            { text: "Passport", route: baseRouteUrl + 'process_ecr/password' },
            { text: "Cruise", route: baseRouteUrl + 'process_ecr/cruise' },
          ]}
        />
        <SliderMenuComponent icon={Payments} text={'Payments'} route={'/DASHBOARD'} />
        <SliderMenuComponent icon={UserManagement} text={'User Management'} subroute={[
          { text: "User", route: baseRouteUrl + 'user_management/users', otherRoutes: ['create_new_user', 'update_user', 'view_user'] },
          { text: "Role", route: baseRouteUrl + 'user_management/roles', otherRoutes: ['create_new_role', 'update_role', 'view_role'] },
          { text: "Privilages", route: baseRouteUrl + 'user_management/privilages' }
        ]} />
        <SliderMenuComponent icon={Reports_icon} text={'Reports'} subroute={[
          { text: "ECR Report" },
          { text: "Sales", route: baseRouteUrl + 'ecr_reports/SalesReport' },//changed by najma on 01-10-2024
          { text: "AR & AP Report", route: baseRouteUrl + 'ecr_reports/AR&AP_Report' },
          { text: "Payment Report" },
          { text: "Tax Report-B2B" },
          { text: "Tax Report-B2C" },
        ]} />
        <SliderMenuComponent icon={Settings_icon} text={'Master Settings'} subroute={[
          { text: "Client", route: baseRouteUrl + 'master_settings/clients', otherRoutes: ['create_new_client', 'update_client', 'view_client'] },
          { text: "Supplier", route: baseRouteUrl + 'master_settings/suppliers', otherRoutes: ['create_new_supplier', 'update_supplier', 'view_supplier'] },
          { text: "Services", route: baseRouteUrl + 'master_settings/services', otherRoutes: ['create_new_service'] },
          { text: "Locations", route: baseRouteUrl + 'master_settings/locations', otherRoutes: ['create_new_location', 'update_location', 'view_location'] },
          { text: "Tax - GST" },
          { text: "Tax - TCS" },
        ]} />
      </div>
    </div>
  )
}

export default SliderMenu