import { createSlice } from "@reduxjs/toolkit";

export const commonSlice=createSlice({
    name:"common",
    initialState:{
        isCollapsed:false
    },
    reducers:{
        updateCollapsedState:(state,actions)=>{
            state.isCollapsed=!state.isCollapsed
        }
    }
})

export const {updateCollapsedState}=commonSlice.actions

export default commonSlice.reducer