import React, { useEffect } from 'react'
import './flightDateChangeForm.css'
import CustomTextField from '../../../../../../components/custom_text_field/CustomTextField';
import { useFormik } from 'formik';
import * as Yup from "yup";
import CustomDatePicker from '../../../../../../components/custom_date_picker/CustomDatePicker';
import dayjs from "dayjs";
function FlightDateChangeForm({ selected_item, on_modal_close, handlePassengerOnChnage }) {
  useEffect(() => {
    const serviceFee = selected_item?.FDCD_service_fee ? selected_item.FDCD_service_fee : 0
    const supplierFee = selected_item?.FDCD_supplier_fee ? selected_item.FDCD_supplier_fee : 0
    const gstFee = selected_item?.FDCD_gst ? selected_item.FDCD_gst : 0
    formik.setValues({
      ...formik.values, ...{
        'key':selected_item?.key,
        "bookingId": selected_item?.bookingId ? selected_item?.bookingId : selected_item?.booking_id,
        "FDCD_passangerID": selected_item?.FDCD_passangerID ? selected_item?.FDCD_passangerID : selected_item?.passenger_id,
        'FDCD_changed_PNR': selected_item?.FDCD_changed_PNR?selected_item.FDCD_changed_PNR:'',
        'FDCD_ticketnumber': selected_item?.FDCD_ticketnumber?selected_item.FDCD_ticketnumber:'',
        'FDCD_changed_travel_date': selected_item?.FDCD_changed_travel_date ? selected_item.FDCD_changed_travel_date : null,
        'FDCD_changed_return_date': selected_item?.FDCD_changed_return_date ? selected_item.FDCD_changed_return_date : null,
        'FDCD_service_fee': serviceFee,
        'FDCD_supplier_fee': supplierFee,
        'FDCD_gst': gstFee,
        'passenger_total_charge': serviceFee + supplierFee + gstFee,
        'OLD_PNR_Number':selected_item?.pnr_number
        
      }
    })
  }, [selected_item])
  const formik = useFormik({
    initialValues: {
      'key':'',
      "bookingId": '',
      "FDCD_passangerID": '',
      'OLD_PNR_Number':'',
      'FDCD_changed_PNR': '',
      'FDCD_ticketnumber': '',
      'FDCD_changed_travel_date': '',
      'FDCD_changed_return_date': '', 
      'FDCD_service_fee': 0,
      'FDCD_supplier_fee': 0,
      'FDCD_gst': 0,
      'passenger_total_charge': 0,
      'FDCD_changed_issue_date':dayjs().format('DD-MM-YYYY')
    },
    validationSchema: Yup.object({
      FDCD_ticketnumber: Yup.string().required("Ticket number is required"),
      FDCD_changed_travel_date: Yup.string().nullable(false).required("Travel date is required"),
    }),
    onSubmit: (values) => {
      handlePassengerOnChnage(values)
      formik.resetForm()
      on_modal_close(false)
    }
  })
  console.log(selected_item?.travel_date)
  return (
    <div className='flight_ticket_date_change_form_super_container'>
      <form id="flight_ticket_date_change_form" className='flight_ticket_date_change_form' onSubmit={formik.handleSubmit}>
        <div className='flight_ticket_date_change_form_main_container'>
          <p className="flight_ticket_date_change_form_legend">Date Change Details</p>
          <div>
            <div className='flight_ticket_date_change_form_sub_container_0'>

              <div>
                <p className='flight_ticket_date_change_form_label'>New PNR</p>
                <CustomTextField
                  // isDisabled={isView}
                  name="FDCD_changed_PNR"
                  placeholder={"Enter new pnr"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.FDCD_changed_PNR}
                  error={formik.touched.FDCD_changed_PNR &&
                    Boolean(formik.errors.FDCD_changed_PNR)
                  }
                  helperText={
                    formik.touched.FDCD_changed_PNR && formik.errors.FDCD_changed_PNR
                  }
                />
              </div>

              <div>
                <p className='flight_ticket_date_change_form_label'>New Ticket Number</p>
                <CustomTextField
                  name="FDCD_ticketnumber"
                  placeholder={"Enter new ticket number"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.FDCD_ticketnumber}
                  error={formik.touched.FDCD_ticketnumber &&
                    Boolean(formik.errors.FDCD_ticketnumber)
                  }
                  helperText={
                    formik.touched.FDCD_ticketnumber && formik.errors.FDCD_ticketnumber
                  }
                />
              </div>

              <div>
                <p className='flight_ticket_date_change_form_label'>New Travel Date</p>
                <div style={{ width: "100%" }}>
                  <CustomDatePicker
                    name="FDCD_changed_travel_date"
                    minDate={dayjs(selected_item?.travel_date, "DD-MM-YYYY")} 
                    onChange={(date) => {
                      formik.setFieldValue("FDCD_changed_travel_date", date);
                    }}
                    onBlur={formik.handleBlur}
                    value={dayjs(formik.values.FDCD_changed_travel_date, "DD-MM-YYYY")}
                    error={
                      formik.touched.FDCD_changed_travel_date &&
                      Boolean(formik.errors.FDCD_changed_travel_date)
                    }
                    helperText={
                      formik.touched.FDCD_changed_travel_date && formik.errors.FDCD_changed_travel_date
                    } />
                </div>

              </div>
              <div>
                <p className='flight_ticket_date_change_form_label'>New Return Date</p>
                <div style={{ width: "100%" }}>
                  <CustomDatePicker
                    name="FDCD_changed_return_date"
                    minDate={dayjs(selected_item?.travel_date, "DD-MM-YYYY")} 
                    onChange={(date) => {
                      formik.setFieldValue("FDCD_changed_return_date", date);
                    }}
                    onBlur={formik.handleBlur}
                    value={dayjs(formik.values.FDCD_changed_return_date, "DD-MM-YYYY")}
                    error={
                      formik.touched.FDCD_changed_return_date &&
                      Boolean(formik.errors.FDCD_changed_return_date)
                    }
                    helperText={
                      formik.touched.FDCD_changed_return_date && formik.errors.FDCD_changed_return_date
                    } />
                </div>

              </div>

              <div>
                <p className='flight_ticket_date_change_form_label'>Supplier Charge</p>
                <CustomTextField
                  type={'number'}
                  name="FDCD_supplier_fee"
                  placeholder={"Enter Airline Charge"}
                  onChange={(e) => {
                    formik.handleChange(e)
                    formik.setFieldValue('passenger_total_charge', Number(e.target.value) + Number(formik.values.FDCD_service_fee) + Number(formik.values.FDCD_gst))
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.FDCD_supplier_fee}
                  error={formik.touched.FDCD_supplier_fee &&
                    Boolean(formik.errors.FDCD_supplier_fee)
                  }
                  helperText={
                    formik.touched.FDCD_supplier_fee && formik.errors.FDCD_supplier_fee
                  }
                />
              </div>

              <div>
                <p className='flight_ticket_date_change_form_label'>Service Charge</p>
                <CustomTextField
                  type={'number'}
                  name="FDCD_service_fee"
                  placeholder={"Enter Supplier Charge"}
                  onChange={(e) => {
                    formik.handleChange(e)
                    formik.setFieldValue('passenger_total_charge', Number(e.target.value) +  Number(formik.values.FDCD_supplier_fee) +  Number(formik.values.FDCD_gst))
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.FDCD_service_fee}
                  error={formik.touched.FDCD_service_fee &&
                    Boolean(formik.errors.FDCD_service_fee)
                  }
                  helperText={
                    formik.touched.FDCD_service_fee && formik.errors.FDCD_service_fee
                  }
                />
              </div>

              <div>
                <p className='flight_ticket_date_change_form_label'>Gst Charge</p>
                <CustomTextField
                  type={'number'}
                  name="FDCD_gst"
                  placeholder={"Enter Gst Charge"}
                  onChange={(e) => {
                    formik.handleChange(e)
                    formik.setFieldValue('passenger_total_charge', Number(e.target.value) +  Number(formik.values.FDCD_supplier_fee) +  Number(formik.values.FDCD_service_fee))
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.FDCD_gst}
                  error={formik.touched.FDCD_gst &&
                    Boolean(formik.errors.FDCD_gst)
                  }
                  helperText={
                    formik.touched.FDCD_gst && formik.errors.FDCD_gst
                  }
                />
              </div>
              <div>
                <p className='flight_ticket_date_change_form_label'>Passeget Total Charge</p>
                <CustomTextField
                  isDisabled={true}
                  name="passenger_total_charge"
                  placeholder={"Enter Gst Charge"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passenger_total_charge}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='flight_ticket_date_change_form_action_container'>
          <div className='flight_ticket_date_change_form_single_row_alignment'>
            <button className='flight_ticket_date_change_form_reset_button' type='button' onClick={()=>{
              formik.resetForm()
            }}>Reset</button>
            <button
              className='flight_ticket_date_change_form_save_button'
              type="submit"
              form="flight_ticket_date_change_form">Save</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default FlightDateChangeForm