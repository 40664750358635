import React, { useEffect } from 'react'
import './userStyle.css'
import { useNavigate } from 'react-router-dom'
import CustomTable from '../../../../components/custom_table/CustomTable'
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { LuKeyRound } from "react-icons/lu";
import { PiEye } from "react-icons/pi";
import { get_users_with_pagination, user_delete, user_search_filter } from '../../../../api/userApis';
import { useState } from 'react';
import { ErrorToast, SuccessToast } from '../../../../toast/toasts';
import CustomBottomPagination from '../../../../components/custom_bottom_pagination/CustomBottomPagination';
import CustomModal from '../../../../components/custom_modal/CustomModal';
import ChangePassword from './change_passoword/ChangePassword';
import UserFilter from './user_filter/UserFilter';
import { LocalStorageServices } from '../../../../services/localStorageServices';
function User() {
  const baseRouteUrl = `/ecrs_portal/${LocalStorageServices.getUserRole()}/user_management`
  const navigate = useNavigate()
  const [usersListing, setUsersListing] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [totalItemPerPage, setTotalItemPerPage] = useState(10)
  const [selectedId, setSelectedId] = useState("");
  const [searchFilter, setSearchFilter] = useState(null)
  const [open, setOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  useEffect(() => {
    get_users(pageNumber, searchFilter)
  }, [pageNumber, totalItemPerPage])
  const get_users = (pageNumber, filterValue) => {
    const formData = new FormData();
    formData.append('page', pageNumber);
    formData.append('per_page', totalItemPerPage);
    if (filterValue) {
      for (const key in filterValue) {
        formData.append(key, filterValue[key]);
      }
    }
    get_users_with_pagination(formData).then((response) => {
      setUsersListing(response.data.data)
      setTotalPages(response.data.total_pages)

    }).catch((error) => {
      let message = "";
      for (const key in error.data.errors) {
        if (error.data.errors.hasOwnProperty(key)) {
          const keyMessages = error.data.errors[key].map(message => message);
          message = message.concat(keyMessages);
        }
      }
      ErrorToast(message)
    })
  }
  const Columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",

    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      wrapper: (row) => (
        <div >
          <p style={{ color: row.status == 'Active' ? '#0A9E02' : "#FF0000" }}>{row.status}</p>
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      action: (row) => (
        <div style={{ display: 'flex', alignItems: 'center', 'gap': 5 }}>
          <PiEye className='user_action_view' onClick={() => {
            navigate(baseRouteUrl + `/${row.id}/view_user`)
          }} />

          <BiEditAlt className='user_action_edit' onClick={() => {
            navigate(baseRouteUrl + `/${row.id}/update_user`)

          }} />

          <LuKeyRound className='user_action_password' onClick={() => {
            setOpen(true)
            setSelectedId(row.id)
          }} />


          <MdOutlineDeleteOutline className='user_action_delete' onClick={() => {
            setDeleteConfirmation(true)
            setSelectedId(row.id)
          }} />
        </div>
      ),
    },
  ];

  const delete_user = (user_id) => {
    user_delete(user_id).then((response) => {
      handleDeleteModalClose(false)
      SuccessToast(response.data.message)
      get_users(pageNumber)
    }).catch((error) => {
      console.log(error)
    })
  }

  const datas = () => {
    const result =
      usersListing &&
      usersListing.map((item, index) => {
        return {
          key: pageNumber * totalItemPerPage - (totalItemPerPage - (index + 1)),
          id: item.UD_id,
          name: item.UD_name,
          username: item.username,
          email: item.UD_email,
          mobile: item.UD_mobile ? item.UD_mobile : '-',
          location: item.location_name,
          role: item.role_name,
          status: item.status_name,
        };
      });
    return result;
  };
  const updatePagination = (pageNumber) => {
    setPageNumber(pageNumber)
  }
  const updateItemPageCount = (pageItemCount) => {
    setTotalItemPerPage(pageItemCount)
  }
  const handleClose = (is_close) => {
    setOpen(is_close);
    setSelectedId(null)
  };
  const handleDeleteModalClose = (is_close) => {
    setDeleteConfirmation(is_close);
    setSelectedId(null)
  };
  const searchfilter = (value) => {
    setSearchFilter(value)
    if (value) {
      get_users(pageNumber, value)
    } else {
      // get_users(1,value)
      setPageNumber(1)
    }


  }
  return (
    <div className='users_module_main_container'>
      <p className='user_title'>User</p>
      <div className='user_module_filter'>
        <UserFilter searchFilter={searchfilter} />
        <button className='user_add_button' onClick={() => {
          navigate(baseRouteUrl + '/create_new_user')
        }}>New</button>
      </div>
      <div className='user_table_main_container'>
        <CustomTable columns={Columns} rows={datas()} />
      </div>
      <div>
        <CustomBottomPagination total_items_per_page={totalItemPerPage} total_page_count={totalPages} current_page={pageNumber} on_change_value={updatePagination} on_page_count_update={updateItemPageCount} />
      </div>
      <div>
        <CustomModal
          open={open}
          handleClose={handleClose}
          body={
            <ChangePassword id={selectedId} onclose={handleClose} />
          }
          title={"Update password"}
        />
        <CustomModal
          open={deleteConfirmation}
          handleClose={handleDeleteModalClose}
          body={
            <div>
              <p className='user_delete_modal_text'>Are you sure you want to delete user ?</p>
              <div className='user_delete_modal_action_container'>
                <button className='user_delete_modal_yes_action' onClick={()=>{
                  delete_user(selectedId)
                }}>Yes</button>
                <button className='user_delete_modal_no_action' onClick={()=>{
                  handleDeleteModalClose(false)
                }}>No</button>
              </div>
            </div>
          }
          title={"Are You Sure ?"}
        />
      </div>
    </div>
  )
}

export default User