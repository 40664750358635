import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
function CustomModal({ open, handleClose, title, close, body ,maxWidth}) {
    return (
        <div>
            <Dialog
                fullWidth={true}
                open={open}
                onClose={() => { handleClose(false) }}
                sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth:maxWidth?maxWidth:"550px",  // Set your width here
                      },
                    },
                  }}
            >
                <DialogTitle fontSize={18} fontWeight={'bold'} fontFamily={"Jost"}>
                    {title}
                </DialogTitle>
                {close}
                <DialogContent>
                    {body}
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default CustomModal