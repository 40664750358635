import React from 'react'
import SliderMenu from './sub_components/slider_menu/SliderMenu'
import Header from './sub_components/header/Header'
import { Outlet } from 'react-router-dom'
import './homePageStyle.css'
function HomePage() {
  return (
    <div className='home_page_main_container'>
        <div className='home_page_sub_container'>
            <SliderMenu/>
            <div className='home_page_sub_header_and_component_container'>
              <Header/>
              <div className='home_page_components_loading_container'>
               <Outlet/>
              </div>
            </div>
        </div>
       
    </div>
  )
}

export default HomePage