import React, { useEffect, useState } from 'react'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown'
import { IoArrowBackSharp } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './clientFormStyle.css'
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { get_status } from '../../../../../api/statusApis';
import { get_city_lists, get_country_lists, get_state_lists } from '../../../../../api/lookupApis';
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts';
import { client_registration, get_client_by_id, update_client_details } from '../../../../../api/clientApis';
function ClientForm() {
    const navigate = useNavigate()
    const [status, setStatus] = useState([])
    const [countryLists, setCountryLists] = useState([])
    const [stateLists, setStateLists] = useState([])
    const [cityLists, setCityLists] = useState([])
    const { client_id } = useParams()
    const location = useLocation()
    const [isView, setIsView] = useState(null)
    useEffect(() => {
        if (location.pathname.split('/').pop() === "view_client") {
            setIsView(true)
        }
        getStatus()
        getCountryLists()
        if (client_id) {
            get_client_by_id(client_id).then((response) => {
                const response_data = response.data.data
                formik.setValues({
                    ...formik.values,
                    "CI_name": response_data.CI_name,
                    "CI_statusId": Number(response_data.CI_statusId),
                    "CI_countryid": response_data.CI_countryid,
                    "CI_stateId": response_data.CI_stateId,
                    "CI_city": response_data.CI_city,
                    "CI_email": response_data.CI_email,
                    "CI_phone": response_data.CI_phone,
                    "CI_address": response_data.CI_address,
                    "CI_pincode":response_data.CI_pincode,
                    "CI_Remarks":response_data.CI_Remarks
                });
                getStateLists(response_data.CI_countryid)
                // getCityLists(response_data?.country_id, response_data?.state_id)
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [])

    const formik = useFormik({
        initialValues: {
            "CI_name": "",
            "CI_statusId": "",
            "CI_countryid": "",
            "CI_stateId": "",
            "CI_city": "",
            "CI_email": "",
            "CI_phone": "",
            "CI_address": "",
            "CI_pincode": "",
            "CI_Remarks": ""
        },
        validationSchema: Yup.object({
            CI_name: Yup.string().required("Name is required"),
            // cd_email: Yup.string().email("Invalid email address").required("Email is required"),
            CI_statusId: Yup.string().required("Status is required"),
        }),
        onSubmit: (values) => {
            const formData = new FormData();

           
            if (!client_id) {
                for (const key in values) {
                    formData.append(key, values[key]);
                }
                client_registration(formData).then((response) => {
                    if (response.status == 200) {
                        navigate(-1)
                        SuccessToast(response.data.status)
                    }
                }).catch((error) => {
                    let message = "";
                    for (const key in error.data) {
                        if (error.data.hasOwnProperty(key)) {
                            const keyMessages = error.data[key].map(message => message);
                            message = message.concat(keyMessages);
                        }
                    }
                    ErrorToast(message)
                })
            } else {
                for (const key in values) {
                    if(values[key]){
                        formData.append(key, values[key]);
                    }
                   
                }
                update_client_details(client_id, formData).then((response) => {
                    if (response.status == 200) {
                        navigate(-1)
                        SuccessToast(response.data.message)
                    }
                }).catch((error) => {
                    console.log(error)
                    let message = "";
                    for (const key in error.data) {
                        if (error.data.hasOwnProperty(key)) {
                            const keyMessages = error.data[key].map(message => message);
                            message = message.concat(keyMessages);
                        }
                    }
                    ErrorToast(message)
                })
            }

        }
    });
    const getStatus = () => {
        get_status().then((response) => {
            const data = response.map((item) => {
                return {
                    label: item.status_name,
                    value: item.id,
                };
            });
            setStatus(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const getCountryLists = () => {
        get_country_lists().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.country_name,
                    value: item.id,
                };
            });
            setCountryLists(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getStateLists = (country_id) => {

        if (country_id) {
            get_state_lists(country_id).then((response) => {
                const data = response?.data?.map((item) => {
                    return {
                        label: item.name,
                        value: item.id,
                    };
                });
                setStateLists(data)
            }).catch((error) => {
                setStateLists([])
                console.log(error)
            })
        } else {
            setStateLists([])
        }

    }
    const getCityLists = (country_id, state_id) => {

        if (country_id && state_id) {
            get_city_lists(country_id, state_id).then((response) => {
                const data = response?.data?.map((item) => {
                    return {
                        label: item.name,
                        value: item.id,
                    };
                });
                setCityLists(data)
            }).catch((error) => {
                setCityLists([])
                console.log(error)
            })
        } else {
            setCityLists([])
        }

    }
    const ResetForm=()=>{
        formik.setValues({
            ...formik.values,
            "CI_name": "",
            "CI_statusId": "",
            "CI_countryid": "",
            "CI_stateId": "",
            "CI_city": "",
            "CI_email": "",
            "CI_phone": "",
            "CI_address": "",
            "CI_pincode": "",
            "CI_Remarks": ""
        });
    }
    return (
        <div className='clients_form_super_container'>
            <div >
                <div className='clients_form_action_sub_container'>
                    <div className='clients_form_single_row_alignment'>
                        <IoArrowBackSharp className='clients_form_back' onClick={() => {
                            navigate(-1)
                        }} />
                        <p className='clients_form_title'>{client_id && isView ? "View" : client_id ? 'Update' : "Add New"} Client</p>
                    </div>
                    {!isView && (
                    <div className='clients_form_single_row_alignment'>
                        {!client_id && (
                            <button className='clients_form_reset_button' onClick={()=>{
                                ResetForm()
                            }}>Reset</button>
                        )}

                        <button className='clients_form_save_button'
                            type="submit"
                            form="clientsform"
                        >Save</button>
                    </div>)}
                </div>

                <div>
                    <form id="clientsform" onSubmit={formik.handleSubmit}>
                        <div className='clients_form_main_container'>
                            <p className="clients_form_legend">Details</p>
                            <div className='clients_form_sub_container_0'>
                                <div>
                                    <p className='clients_form_label'>Client Name</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="CI_name"
                                        placeholder={"Enter client name"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.CI_name}
                                        error={formik.touched.CI_name &&
                                            Boolean(formik.errors.CI_name)
                                        }
                                        helperText={
                                            formik.touched.CI_name && formik.errors.CI_name
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='clients_form_label'>Email</p>
                                    <CustomTextField
                                       isDisabled={isView}
                                        name="CI_email"
                                        placeholder={"Enter email"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.CI_email}
                                        error={formik.touched.CI_email &&
                                            Boolean(formik.errors.CI_email)
                                        }
                                        helperText={
                                            formik.touched.CI_email && formik.errors.CI_email
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='clients_form_label'>Phone Number</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="CI_phone"
                                        placeholder={"Enter phone number"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.CI_phone}
                                        error={formik.touched.CI_phone &&
                                            Boolean(formik.errors.CI_phone)
                                        }
                                        helperText={
                                            formik.touched.CI_phone && formik.errors.CI_phone
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='clients_form_label'>Address</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="CI_address"
                                        placeholder={"Enter address"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.CI_address}
                                        error={formik.touched.CI_address &&
                                            Boolean(formik.errors.CI_address)
                                        }
                                        helperText={
                                            formik.touched.CI_address && formik.errors.CI_address
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='clients_form_label'>Country</p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="CI_countryid"
                                        placeholder={"Select a country"}
                                        options={countryLists}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "CI_countryid",
                                                newValue ? newValue.value : ""
                                            );
                                            getStateLists(newValue?.value)
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            countryLists.find((country) =>
                                                country.value ===
                                                formik.values.CI_countryid
                                            ) || null
                                        }
                                        error={
                                            formik.touched.CI_countryid &&
                                            Boolean(formik.errors.CI_countryid)
                                        }
                                        helperText={
                                            formik.touched.CI_countryid &&
                                            formik.errors.CI_countryid
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='clients_form_label'>State</p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="CI_stateId"
                                        placeholder={"Select a state"}
                                        options={stateLists}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "CI_stateId",
                                                newValue ? newValue.value : ""
                                            );
                                            // getCityLists(formik.values.CI_countryid, newValue?.value)
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            stateLists.find((state) =>
                                                state.value ===
                                                formik.values.CI_stateId
                                            ) || null
                                        }
                                        error={
                                            formik.touched.CI_stateId &&
                                            Boolean(formik.errors.CI_stateId)
                                        }
                                        helperText={
                                            formik.touched.CI_stateId &&
                                            formik.errors.CI_stateId
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='clients_form_label'>City</p>
                                    <CustomTextField
                                       isDisabled={isView}
                                        name="CI_city"
                                        placeholder={"Enter city"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.CI_city}
                                        error={formik.touched.CI_city &&
                                            Boolean(formik.errors.CI_city)
                                        }
                                        helperText={
                                            formik.touched.CI_city && formik.errors.CI_city
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='clients_form_label'>Postal Code</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="CI_pincode"
                                        placeholder={"Enter postal code"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.CI_pincode}
                                        error={formik.touched.CI_pincode &&
                                            Boolean(formik.errors.CI_pincode)
                                        }
                                        helperText={
                                            formik.touched.CI_pincode && formik.errors.CI_pincode
                                        }
                                    />
                                </div>


                                <div>
                                    <p className='clients_form_label'>Status</p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="CI_statusId"
                                        placeholder={"Select a status"}
                                        options={status}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "CI_statusId",
                                                newValue ? newValue.value : ""
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            status.find((status) =>
                                                status.value ===
                                                formik.values.CI_statusId
                                            ) || null
                                        }
                                        error={
                                            formik.touched.CI_statusId &&
                                            Boolean(formik.errors.CI_statusId)
                                        }
                                        helperText={
                                            formik.touched.CI_statusId &&
                                            formik.errors.CI_statusId
                                        }
                                    />
                                </div>
                            </div>

                            <div className='clients_form_sub_container_1'>
                                <p className='clients_form_label'>Remarks</p>
                                <CustomTextArea
                                   isDisabled={isView}
                                    rows={6}
                                    name="CI_Remarks"
                                    placeholder={"Enter a remark"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.CI_Remarks}
                                    error={formik.touched.CI_Remarks &&
                                        Boolean(formik.errors.CI_Remarks)
                                    }
                                    helperText={
                                        formik.touched.CI_Remarks && formik.errors.CI_Remarks
                                    }
                                />
                            </div>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    )
}

export default ClientForm