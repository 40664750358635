import React, { useState } from 'react'
import CustomModal from '../../../../../components/custom_modal/CustomModal'
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import './flightTicketFinanceLevelModals.css'
import { CommonHelpers } from '../../../../../services/commonHelpers'
import { RiDownloadLine } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import { approve_or_reject_filght_ticket } from '../../../../../api/process_ecr/flightTicketApis'
import { SuccessToast } from '../../../../../toast/toasts'
function FlightTicketFinanceLevelModals({ selected_item, open_modal, modal_close }) {
    const [attachments,setAttachments]=useState([])
    const [fieldValues, setfieldValues] = useState({
        'invoice_number':'',
        'comment':''
    })
    const [showError, setShowError] = useState({
        'invoice_number':'',
        'comment':''
    })
    const onSubmit = () => {
        let errors={
            'invoice_number':'',
            'comment':''
        }
        if (!fieldValues.comment.trim()){
            errors.comment="Comment is required"
        }
        if(selected_item?.type === "Verified" &&  !fieldValues.invoice_number.trim()){
            errors.invoice_number="Invoice number is required"
        }

        if(!errors.comment.trim() && !errors.invoice_number.trim()){
            const formData = new FormData();
            formData.append('bookingId', selected_item?.id)
            formData.append('PNR_Number', selected_item?.pnr)
            formData.append('FBD_ECRstatusID', selected_item?.type === "OnHold" ? 7 : 4)
            formData.append('FBRH_invoice_number', fieldValues.invoice_number)
            formData.append('FBRH_review_comment', fieldValues.comment)
            attachments.map((item, index) => {
                formData.append(`file[${index}]`, item.file);
            })
            approve_or_reject_filght_ticket(formData).then((response)=>{
                if(response.status===200){
                    if(selected_item?.type === "OnHold"){
                        SuccessToast('Sucessfully changes status to hold')
                    }else{
                        SuccessToast('Sucessfully verified')
                    }
                    modal_close(false)
                    resetStates()
                }
            }).catch((error)=>{
                console.log(error)
            })

        }else{
            setShowError(errors)
        }
       

    }
    function handleFileSelect(event) {
        const fileInput = event.target;
        const file = fileInput.files[0];
        const fileName = file.name;
        const fileType = file.type.split("/")[1];
        setAttachments([...attachments,{ file: file, fileName: fileName, fileType: fileType }])
    }
    const delete_particular_attachment= (index, item) => {
        attachments.splice(index, 1)
        setAttachments([...attachments])
    }
    const resetStates=()=>{
        setAttachments([])
        setfieldValues({
            'invoice_number':'',
            'comment':''
        })
        setShowError({
            'invoice_number':'',
            'comment':''
        })
    }
    return (
        <div>
            <CustomModal
                open={open_modal}
                handleClose={(e)=>{
                    modal_close(e)
                    resetStates()
                }}
                body={
                    <div>
                        <div>
                            <p className='finance_level_modal_label'>Invoice Number</p>
                            <CustomTextField
                                placeholder={"Enter a invoice number"}
                                onChange={(e) => {
                                    setfieldValues({...fieldValues,'invoice_number':e.target.value})
                                    if(showError.invoice_number.length>0){
                                        setShowError({...showError,['invoice_number']:''})
                                    }
                                }}
                            />
                            {(selected_item?.type === "Verified" && showError.invoice_number.length>0) && (<p className='comment_error_message'>{showError.invoice_number}</p>)}
                        </div>
                        <div>
                            <p className='finance_level_modal_label'>Comment</p>
                            <CustomTextArea
                                rows={3}
                                placeholder={"Enter a comment"}
                                onChange={(e) => {
                                    setfieldValues({...fieldValues,'comment':e.target.value})
                                    if(showError.comment.length>0){
                                        setShowError({...showError,['comment']:''})
                                    }
                                   
                                }}
                            />
                            {showError.comment.length>0 && (<p className='comment_error_message'>{showError.comment}</p>)}
                        </div>

                        <div>
                            <p className='finance_level_modal_label'>Attachment</p>
                            <div style={{ margin: '20px 0px' }}>
                                <label htmlFor="files" className="flight_ticket_choose_file_field">
                                    <span id="fileInfo">Choose file</span>
                                </label>
                                <input
                                    id="files"
                                    style={{ display: "none" }}
                                    type="file"
                                    onChange={(event) => handleFileSelect(event)}
                                />
                            </div>
                            <div>
                            {attachments && attachments.length > 0 && attachments.map((item, index) => (
                                <div className='flight_ticket_additional_info_attchment_item'>
                                    <p>{item.fileName ? item.fileName : item.FBPD_file}</p>
                                    <div>
                                        <RiDownloadLine onClick={() => {
                                            if (item.url) {
                                                CommonHelpers.DownloadAssets(item.url)
                                            } else {
                                                CommonHelpers.DownloadFileObjectAssets(item.fileName, item.file)
                                            }
                                        }} />
                                         <MdDeleteOutline  onClick={() => {
                                                delete_particular_attachment(index, item)
                                            }} />

                                    </div>
                                </div>
                            ))}
                            </div>
                        </div>

                        <div className='finance_level_modal_form_single_row_alignment'>
                            <button className='finance_level_modal_form_reset_button' onClick={() => {
                                modal_close(false)
                                resetStates()
                            }}>Cancel</button>

                            <button
                                className='finance_level_modal_form_update_button' onClick={() => {
                                    onSubmit()
                                }}>{selected_item?.type === "OnHold" ? "Hold" : "Verify"}</button>
                        </div>
                    </div>
                }
                title={selected_item?.type === "OnHold" ? "Hold Ticket" : "Verify Ticket"}
            />
        </div>
    )
}

export default FlightTicketFinanceLevelModals