import React, { useEffect, useState } from 'react'
import CustomTableWithCheckBox from '../../../../../components/custom_table_with_checkbox/CustomTableWithCheckBox'
import './flightCancelFormStyle.css'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea'
import { useFormik } from 'formik'
import * as Yup from "yup";
import FlightTicketCancelConfirmation from './flight_ticket_cancel_confirm/FlightTicketCancelConfirmation'
import CustomModal from '../../../../../components/custom_modal/CustomModal'
import { IconButton } from '@mui/material'
import { BiEditAlt } from "react-icons/bi";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { TiTickOutline } from "react-icons/ti"
import { GrPowerReset } from "react-icons/gr";
import { ErrorToast } from '../../../../../toast/toasts'
import CustomTableColumnWithEditableRow from '../../../../../components/custom_table_column_with_editable_row/CustomTableColumnWithEditableRow'
import CustomDatePicker from '../../../../../components/custom_date_picker/CustomDatePicker'
import dayjs from "dayjs";
function FlightCancelForm({ details, onClose }) {
    const [checkItems, setCheckedItems] = useState([])
    const [cancelConfirmModal, setCancelConfirmModal] = useState(false)
    const [passengerDetails, setPassengerDetails] = useState([])
    const [passengerDetailsCopyState, setPassengerDetailsCopyState] = useState([])
    const [onRowUpdateValue, setRowUpdateValue] = useState({})
    const [passengerCounts, setPassengerCounts] = useState({
        'adult': 0,
        'child': 0,
        'infants': 0
    })
    const [amount, setAmount] = useState({
        totalAmount: 0,
        cancellationFee: 0
    })
    const [formDetails, setFormDetails] = useState(null)
    const [basicDetails, setBasicDetails] = useState({
        remark: '',
        date_of_cancellation: ''
    })
    useEffect(() => {
        passengerCount()
        setPassengerDetails(datas(details?.passanges))
        setPassengerDetailsCopyState(datas(details?.passanges))
    }, [details])

    const Columns = [
        {
            title: "check",
            dataIndex: "header_checkbox",
            key: "header_checkbox",
            action: (row) => (
                <input type='checkbox' className='custom_check_box_color' checked={checkItems?.length == passengerDetails?.length ? true : checkItems?.includes(row?.key)} onChange={() => {
                    if (Array.isArray(row)) {
                        if (checkItems.length == passengerDetails.length) {
                            setCheckedItems([])
                            setAmount({ ...amount, ['totalAmount']: 0, ['cancellationFee']: 0 })
                        } else {
                            let totalAmount = 0
                            let cancellationFee=0
                            setCheckedItems(row.map((item) => item.key))
                            row.map((item) => {
                                const {airline_supplier_fee,gst,las_service_fee,customer_net}=item
                                totalAmount = totalAmount + Number(customer_net)
                                cancellationFee=cancellationFee+airline_supplier_fee+gst+las_service_fee
                            })
                            setAmount({ ...amount, ['totalAmount']: totalAmount ,['cancellationFee']:cancellationFee})

                        }
                    } else {
                        if (checkItems.includes(row.key)) {
                            setCheckedItems(checkItems.filter((item) => item !== row.key))
                            const {airline_supplier_fee,gst,las_service_fee}=row
                            setAmount({ ...amount, ['totalAmount']: amount.totalAmount - Number(row.customer_net),['cancellationFee']:amount.cancellationFee-(airline_supplier_fee+gst+las_service_fee)})
                        } else {
                            setCheckedItems([...checkItems, row.key])
                            const {airline_supplier_fee,gst,las_service_fee}=row
                            setAmount({ ...amount, ['totalAmount']: amount.totalAmount + Number(row.customer_net),['cancellationFee']:amount.cancellationFee+(airline_supplier_fee+gst+las_service_fee)})
                        }
                    }
                }} />
            ),

        },
        {
            title: "#",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },

        {
            title: "Passenger Type",
            dataIndex: "passenger_type",
            key: "passenger_type",
        },
        {
            title: "Ticket Number",
            dataIndex: "ticket_number",
            key: "ticket_number",
        },
        {
            title: "Current Supplier Fee",
            dataIndex: "supplier_amount",
            key: "supplier_amount",
        },
        {
            title: "Current Service Fee",
            dataIndex: "service_charge",
            key: "service_charge",
        },
        {
            title: "Current Customer Net",
            dataIndex: "customer_net",
            key: "customer_net",
        },
        {
            title: "Cancel Supplier Fee",
            dataIndex: "airline_supplier_fee",
            key: "airline_supplier_fee",
            editableField: (row) => (
                <div>
                    {checkItems.includes(row.key) ? (
                        <CustomTextField type={'number'} inputProps={{ inputProps: { min: 0, max: Number(row.customer_net) } }} defaultValue={row.airline_supplier_fee}
                            onBlur={(e) => {
                                updateIsEditableFieldValue(row.key - 1, 'number', 'airline_supplier_fee', e.target.value)

                            }}
                        />
                    ) : <p>{row.airline_supplier_fee}</p>}


                </div>
            )
        },
        {
            title: "Cancel Service Fee",
            dataIndex: "las_service_fee",
            key: "las_service_fee",
            editableField: (row) => (
                <div>
                    {checkItems.includes(row.key) ? (
                        <CustomTextField type={'number'} inputProps={{ inputProps: { min: 0, max: Number(row.customer_net) } }} defaultValue={row.las_service_fee}
                            onBlur={(e) => {
                                updateIsEditableFieldValue(row.key - 1, 'number', 'las_service_fee', e.target.value)
                            }}
                        />
                    ) : <p>{row.las_service_fee}</p>}
                </div>
            )
        },
        {
            title: "GST",
            dataIndex: "gst",
            key: "gst",
            editableField: (row) => (
                <div>
                    {checkItems.includes(row.key) ? (
                        <CustomTextField type={'number'} inputProps={{ inputProps: { min: 0, max: Number(row.customer_net) } }} defaultValue={row.gst}
                            onBlur={(e) => {
                                updateIsEditableFieldValue(row.key - 1, 'number', 'gst', e.target.value)
                            }}
                        />
                    ) : <p>{row.gst}</p>}

                </div>
            )
        },
        // {
        //     title: "Actions",
        //     dataIndex: "actions",
        //     key: "actions",
        //     action: (row) => (
        //         <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        //             {row.isEditable && <TiTickOutline className='flight_ticket_cancel_action_save' />}
        //             {!row.isEditable && <BiEditAlt className='flight_ticket_cancel_action_edit' onClick={()=>{
        //                 updateIsEditableState(row.key-1)
        //             }} />}
        //             {row.isEditable && <GrPowerReset className='flight_ticket_cancel_action_reset' onClick={(e)=>{
        //                 e.preventDefault();
        //                 resetFieldValues(row.key-1)
        //             }} />}
        //         </div>
        //     ),
        // }
    ]

    const datas = (passengerDetails) => {
        const result =
            passengerDetails &&
            passengerDetails.map((item, index) => {
                return {
                    key: index + 1,
                    id: item.FBPD_id,
                    name: item.FBPD_FullName,
                    passenger_type: item.FBPD_Passanger_type,
                    ticket_number: item.FBPD_ticket_number,
                    supplier_amount: item.FBPD_supplier_amount,
                    service_charge: item.FBPD_service_fee,
                    customer_net: item.FBPD_customer_net,
                    airline_supplier_fee: 0,
                    las_service_fee: 0,
                    gst: 0,
                    isEditable: false
                };
            });
        return result;
    };
    const passengerCount = () => {
        let adultCount = 0;
        let childCount = 0;
        let infantCount = 0;
        let passengerNet = 0
        details?.passanges?.forEach((item) => {
            passengerNet = passengerNet + Number(item.FBPD_customer_net)
            if (item.FBPD_Passanger_type === 1) adultCount++;
            else if (item.FBPD_Passanger_type === 2) childCount++;
            else if (item.FBPD_Passanger_type === 3) infantCount++;

        });

        setPassengerCounts({
            adult: adultCount,
            child: childCount,
            infants: infantCount,
        });
        // console.log(passengerNet)
        // console.log('executed1')
        // setAmount({ ...amount, ['totalAmount']: passengerNet })
    }
    // const formik = useFormik({
    //     initialValues: {
    //         'airline_cancellation_charge': 0,
    //         'supplier_cancellation_charge': 0,
    //         'las_service_charges': 0,
    //         'gst': 0,
    //         'narration': ''
    //     },
    //     validationSchema: Yup.object({
    //         airline_cancellation_charge: Yup.number().required("Airline Cancellation charge is required"),
    //         supplier_cancellation_charge: Yup.number().required("Supplier Cancellation charge is required"),
    //         las_service_charges: Yup.number().required("Las service charge is required"),
    //         gst: Yup.number().required("Gst charge is required")
    //     }),
    //     onSubmit: (values) => {
    //         let totalAmount=amount.totalAmount
    //         let refundAmount=amount.totalAmount - amount.cancellationFee

    //         if(refundAmount<0) return ErrorToast('Please check the calculation')
    //         if (checkItems.length === 0) {
    //             ErrorToast('Please select passenger')
    //         } else {
    //             setFormDetails(values)
    //             setCancelConfirmModal(true)
    //         }

    //     }
    // })
    // const updateSupplierAmount = (type, value) => {
    //     // Extract the formik values for reusability
    //     const { airline_cancellation_charge, supplier_cancellation_charge, las_service_charges, gst } = formik.values;

    //     // Base calculation excluding the current 'type'
    //     let baseAmount = airline_cancellation_charge + supplier_cancellation_charge + las_service_charges + gst;

    //     // Subtract the existing value based on the type and add the new value
    //     switch (type) {
    //         case 'airline_cancellation':
    //             baseAmount -= airline_cancellation_charge;
    //             break;
    //         case 'supplier_cancellation':
    //             baseAmount -= supplier_cancellation_charge;
    //             break;
    //         case 'las_service':
    //             baseAmount -= las_service_charges;
    //             break;
    //         case 'gst':
    //             baseAmount -= gst;
    //             break;
    //     }

    //     // Update the supplier amount
    //     setAmount({ ...amount, supplierAmount: baseAmount + Number(value) });
    // }
    const handleCancelModalClose = (isclose) => {
        setCancelConfirmModal(isclose)
    }
    const handleCancelModalTotalClose = (isclose) => {
        onClose(isclose)
        setCancelConfirmModal(isclose)
    }

    // const updateIsEditableState = (index) => {
    //     const passengerDetailsCopy = JSON.parse(JSON.stringify(passengerDetails))
    //     passengerDetailsCopy[index].isEditable = true
    //     setPassengerDetails(passengerDetailsCopy)
    // }

    const updateIsEditableFieldValue = (index, type, key, value) => {
        const passengerDetailsCopy = JSON.parse(JSON.stringify(passengerDetails))
        passengerDetailsCopy[index][key] = type === "number" ? Number(value) : value
        UpdateRefundAmount(passengerDetailsCopy)
        setPassengerDetails(passengerDetailsCopy)
    }

    // const updateRowFieldValue = (index, type, key, value) => {
    //     const passengerDetailsCopy = JSON.parse(JSON.stringify(passengerDetails))
    //     passengerDetailsCopy[index][key] = type === "number" ? Number(value) : value
    //     setPassengerDetails(passengerDetailsCopy)
    // }


    // const resetFieldValues = (index) => {
    //     const passengerDetailsCopy = JSON.parse(JSON.stringify(passengerDetails))
    //     passengerDetailsCopy[index] = passengerDetailsCopyState[index]
    //     setPassengerDetails(passengerDetailsCopy)
    // }

    const UpdateRefundAmount = (passengerDetails) => {
        let CancellationAmount = 0
        passengerDetails && passengerDetails.forEach((item) => {
            CancellationAmount = CancellationAmount + item.airline_supplier_fee + item.las_service_fee + item.gst
        });
        setAmount({ ...amount, ['cancellationFee']: CancellationAmount })
    }
    const onProceed = () => {
        let refundAmount = amount.totalAmount - amount.cancellationFee

        if (refundAmount < 0) return ErrorToast('Please check the calculation')
        if (checkItems.length === 0) {
            ErrorToast('Please select passenger')
        } else {
            const checkedPassengers=passengerDetails.filter((item)=>checkItems.includes(item.key))
            setFormDetails({passengerData:checkedPassengers,basicDetails:{...basicDetails,'booking_id':details.FBD_Id,'pnr_number':details.FBD_PNR},
                amount:{totalAmount:amount.totalAmount,cancellationFee:amount.cancellationFee,refundAmount:refundAmount}})
            setCancelConfirmModal(true)
        }

    }

return (
    <div>
        <CustomModal
            maxWidth={"1500px"}
            open={cancelConfirmModal}
            handleClose={handleCancelModalClose}
            close={<IconButton
                aria-label="close"
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
                onClick={() => {
                    handleCancelModalClose(false)
                }}
            > <IoIosCloseCircleOutline />
            </IconButton>}
            body={
                <FlightTicketCancelConfirmation
                    formDetails={formDetails}
                    amount={amount}
                    handleCloseCancelConfirmModal={handleCancelModalClose}
                    handleCloseAllConfirmModal={handleCancelModalTotalClose}
                />
            }
            title={"Cancel Ticket"}
        />
        <>
            <div className='flight_cancel_form_main_container'>
                <p className="flight_cancel_form_legend">Passenger Details</p>
                <div >
                    <div className='flight_cancel_passenger_count'>
                        <p>Adult(s) : {passengerCounts.adult}</p>
                        <p>Child(s) : {passengerCounts.child}</p>
                        <p>Infants(s) : {passengerCounts.infants}</p>
                    </div>
                    <CustomTableColumnWithEditableRow columns={Columns} rows={passengerDetails} checkItems={checkItems} no_border={true} />
                </div>
            </div>

            <div className='flight_cancel_form_main_container'>
                <p className="flight_cancel_form_legend">Cancellation Details</p>
                {/* <form id="flight_cancel_form" onSubmit={formik.handleSubmit} >
                        <div className='flight_cancel_form_sub_container'>
                            <div>
                                <div className='flight_cancel_form_row_alignment'>
                                    <p className='flight_cancel_form_label_text'>Airlines Cancellation Charges</p>
                                    <CustomTextField
                                        type={'number'}
                                        name="airline_cancellation_charge"
                                        placeholder={"Enter airline cancellation charge"}
                                        onChange={(e) => {
                                            formik.handleChange(e)
                                            if (e.target.value !== '') {
                                                updateSupplierAmount('airline_cancellation', Number(e.target.value))
                                            } else {
                                                updateSupplierAmount('airline_cancellation', 0)
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.airline_cancellation_charge}
                                        error={formik.touched.airline_cancellation_charge &&
                                            Boolean(formik.errors.airline_cancellation_charge)
                                        }
                                        helperText={
                                            formik.touched.airline_cancellation_charge && formik.errors.airline_cancellation_charge
                                        }
                                    />
                                </div>

                                <div className='flight_cancel_form_row_alignment'>
                                    <p className='flight_cancel_form_label_text'>Supplier Cancellation Charges</p>
                                    <CustomTextField
                                        type={'number'}
                                        name="supplier_cancellation_charge"
                                        placeholder={"Enter supplier cancellation charge"}
                                        onChange={(e) => {
                                            formik.handleChange(e)
                                            if (e.target.value !== '') {
                                                updateSupplierAmount('supplier_cancellation', Number(e.target.value))
                                            } else {
                                                updateSupplierAmount('supplier_cancellation', 0)
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.supplier_cancellation_charge}
                                        error={formik.touched.supplier_cancellation_charge &&
                                            Boolean(formik.errors.supplier_cancellation_charge)
                                        }
                                        helperText={
                                            formik.touched.supplier_cancellation_charge && formik.errors.supplier_cancellation_charge
                                        }

                                    />
                                </div>

                                <div className='flight_cancel_form_row_alignment'>
                                    <p className='flight_cancel_form_label_text'>LAS Service Charges</p>
                                    <CustomTextField
                                        type={'number'}
                                        name="las_service_charges"
                                        placeholder={"Enter las service charge"}
                                        onChange={(e) => {
                                            formik.handleChange(e)
                                            if (e.target.value !== '') {
                                                updateSupplierAmount('las_service', Number(e.target.value))
                                            } else {
                                                updateSupplierAmount('las_service', 0)
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.las_service_charges}
                                        error={formik.touched.las_service_charges &&
                                            Boolean(formik.errors.las_service_charges)
                                        }
                                        helperText={
                                            formik.touched.las_service_charges && formik.errors.las_service_charges
                                        }

                                    />
                                </div>

                                <div className='flight_cancel_form_row_alignment'>
                                    <p className='flight_cancel_form_label_text'>GST(18%)</p>
                                    <CustomTextField
                                        type={'number'}
                                        name="gst"
                                        placeholder={"Enter gst charge"}
                                        onChange={(e) => {
                                            formik.handleChange(e)
                                            if (e.target.value !== '') {
                                                updateSupplierAmount('gst', Number(e.target.value))
                                            } else {
                                                updateSupplierAmount('gst', 0)
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.gst}
                                        error={formik.touched.gst &&
                                            Boolean(formik.errors.gst)
                                        }
                                        helperText={
                                            formik.touched.gst && formik.errors.gst
                                        }

                                    />
                                </div>
                                <div className='flight_cancel_form_row_alignment'>
                                    <p className='flight_cancel_form_label_total_text'>Total Amount</p>
                                    <p className='flight_cancel_form_label_total_text'>{amount.totalAmount}</p>
                                </div>
                                <div className='flight_cancel_form_row_alignment'>
                                    <p className='flight_cancel_form_label_total_text'>Refund Amount</p>
                                    <p className='flight_cancel_form_label_total_text'>{amount.supplierAmount}</p>
                                </div>

                            </div>
                            <div>
                                <p className='flight_cancel_form_label_text'>Narration</p>
                                <CustomTextArea
                                    rows={6}
                                    name="narration"
                                    placeholder={"Enter a remark"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.narration}

                                />
                            </div>
                        </div>

                    </form> */}
                <div className='flight_cancel_form_sub_container'>
                    <div >
                        <div className='flight_cancel_form_row_alignment' style={{ maxWidth: '500px' }}>
                            <p className='flight_cancel_form_label_text'>Date Of Cancellation</p>
                            <CustomDatePicker
                                name="airline_cancellation_date"
                                onChange={(e) => {
                                    setBasicDetails({ ...basicDetails, ['date_of_cancellation']: e })
                                }}
                            />
                        </div>
                        <div className='flight_cancel_form_row_alignment'>
                            <p className='flight_cancel_form_label_total_text'>No Of Passengers</p>
                            <p className='flight_cancel_form_label_total_text'>{checkItems.length}</p>
                        </div>
                        <div className='flight_cancel_form_row_alignment'>
                            <p className='flight_cancel_form_label_total_text'>Total Amount</p>
                            <p className='flight_cancel_form_label_total_text'>{amount.totalAmount}</p>
                        </div>
                        <div className='flight_cancel_form_row_alignment'>
                            <p className='flight_cancel_form_label_total_text'>Total Cancellation Fee</p>
                            <p className='flight_cancel_form_label_total_text'>{amount.cancellationFee}</p>
                        </div>
                        <div className='flight_cancel_form_row_alignment'>
                            <p className='flight_cancel_form_label_total_text'>Refund Amount</p>
                            <p className='flight_cancel_form_label_total_text'>{amount.totalAmount - amount.cancellationFee}</p>
                        </div>

                    </div>
                    <div>
                        <div className='flight_cancel_form_remark_container'>
                            <p className="flight_cancel_form_remark_legend">Remarks</p>
                            <CustomTextArea
                                noBorder={true}
                                rows={6}
                                name="narration"
                                placeholder={"Enter a remarks"}
                                onChange={(e) => {
                                    setBasicDetails({ ...basicDetails, ['remark']: e.target.value })
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flight_cancel_form_single_row_alignment'>
                <button className='flight_cancel_form_reset_button' onClick={() => {
                    onClose(false)
                }} >Close</button>
                <button
                    className='flight_cancel_form_save_button' onClick={()=>{
                        onProceed()
                    }}>Proceed</button>
            </div>
        </>


    </div>
)
}

export default FlightCancelForm