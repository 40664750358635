import React, { useState } from 'react'
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { FaAngleDown } from "react-icons/fa6";
import './sliderMenuComponentStyle.css'
import submenu_logo from '../../assets/submenu_logo.png'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CustomPopOverMenu from '../custom_pop_over_menu/CustomPopOverMenu';
function SliderMenuComponent({ icon, text, route, subroute }) {
    const [isExpanded, setIsExpanded] = useState(false)
    const [popOverMenu, setpopOverMenu] = useState(false);
    const navigate = useNavigate()
    const isCollapsed = useSelector((state) => state.commonStore.isCollapsed)
    const updatePopOverState = (isShow) => {
        setpopOverMenu(isShow);
    };
    return (
        <div>
            <div className='slider_menu_main_component'
                style={{ backgroundColor: window.location.pathname.replace(/%20/g, ' ') == route ? '#5C7F7F' : '', justifyContent: isCollapsed ? 'center' : 'space-between' }}
                onClick={() => {
                    if (subroute) {
                        setIsExpanded(!isExpanded)
                    } else {
                        navigate(route)
                    }
                }}
                onMouseEnter={() => {
                    if (isCollapsed) {
                        updatePopOverState(true);
                    }
                }}
                onMouseLeave={() => {
                    if (isCollapsed) {
                        updatePopOverState(false);
                    }
                }}
            >
                <div className='slider_menu_sub_component'>
                    <img src={icon} className='slider_menu_icon' />
                    {!isCollapsed && (
                        <p className='slider_menu_text'>{text}</p>
                    )}
                </div>
                {subroute && (
                    <div>
                        {!isCollapsed && (
                            isExpanded ? <IoIosArrowUp className='slider_dropdown_icon' /> :
                                <IoIosArrowDown className='slider_dropdown_icon' />
                        )}

                    </div>
                )}
                {isCollapsed && popOverMenu && (
                    <div>
                        <CustomPopOverMenu
                            visibilityChange={updatePopOverState}
                            text={text}
                            initialRoute={route}
                            subRoute={subroute}
                        />
                    </div>
                )}

            </div>

            {isExpanded && !isCollapsed && subroute && (
                <div>
                    {subroute.map((item, index) => {
                        return (
                            <div className='slider_submenu_item' style={{ backgroundColor: window.location.pathname.replace(/%20/g, ' ') == item.route ? '#5C7F7F' : item?.otherRoutes?.includes(window.location.pathname.split("/").pop())? '#5C7F7F': '' }} key={index} onClick={() => {
                                navigate(item.route)
                            }}>
                                <img src={submenu_logo} />
                                <p className='slider_submenu_item_text'>{item.text}</p>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>

    )
}

export default SliderMenuComponent