
import React, { useState } from 'react'
import './userFormStyle.css'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown'
import { IoArrowBackSharp } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { get_user_by_id, update_user_details, user_registration, User_resgistration } from '../../../../../api/userApis';
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts';
import { useEffect } from 'react';
import { get_roles_with_out_pagination } from '../../../../../api/rolesApis';
import { get_status } from '../../../../../api/statusApis';
import { get_locations_with_out_pagination } from '../../../../../api/locationApis';
function UserForm() {
    let { user_id } = useParams();
    const location = useLocation()
    const [isView, setIsView] = useState(null)
    const [role, setRole] = useState([])
    const [state, setStatus] = useState([])
    const [locations, setlocation] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        if (location.pathname.split('/').pop() === "view_user") {
            setIsView(true)
        }
        getRolesWithOutPagination()
        getStatus()
        getLocations()
        if (user_id) {
            get_user_by_id(user_id).then((response) => {
                const response_data = response.data.data
                formik.setValues({
                    ...formik.values,
                    'UD_name': response_data.UD_name,
                    'UD_email': response_data.UD_email,
                    'UD_mobile':response_data.UD_mobile,
                    'UD_roleID':response_data.UD_roleID,
                    'UD_statusId':response_data.UD_statusId,
                    'username': response_data.username,
                    'UD_locationId': response_data.UD_locationId?Number(response_data.UD_locationId):response_data.UD_locationId,
                    "paramId":response_data.UD_id,
                });
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [])

    const getStatus = () => {
        get_status().then((response) => {
            const data = response.map((item) => {
                return {
                    label: item.status_name,
                    value: item.id,
                };
            });
            setStatus(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getRolesWithOutPagination = () => {
        get_roles_with_out_pagination().then((response) => {
            const data = response.map((item) => {
                return {
                    label: item.role_name,
                    value: item.role_id,
                };
            });
            setRole(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getLocations = () => {
        get_locations_with_out_pagination().then((response) => {
            const data = response?.data.map((item) => {
                return {
                    label: item.ld_name,
                    value: item.ld_id,
                };
            });
            setlocation(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const formik = useFormik({
        initialValues: {
            'UD_name': '',
            'UD_email': '',
            'password': '',
            "confirm_password": '',
            'UD_mobile': '',
            'UD_roleID': '',
            'UD_statusId': 1,
            'username': '',
            'UD_locationId': '',
            paramId: user_id || false,
        },
        validationSchema: Yup.object({
            UD_name: Yup.string().required("Name is required"),
            username: Yup.string().when("paramId", {
                is: (paramId) => !paramId,
                then: (schema) =>
                    schema
                        .required("Username is required"),
                otherwise: (schema) => schema,
            }),
            UD_email: Yup.string().email("Invalid email address").required("Email is required"),
            password: Yup.string().when("paramId", {
                is: (paramId) => !paramId,
                then: (schema) =>
                    schema
                        .required("Password is required")
                        .min(6, "Password must be at least 6 characters"),
                otherwise: (schema) => schema,
            }),
            confirm_password: Yup.string().when("paramId", {
                is: (paramId) => !paramId,
                then: (schema) =>
                    schema
                        .required("Confirm Password is required")
                        .oneOf([Yup.ref("password"), null], "Passwords must match"),
                otherwise: (schema) => schema,
            }),
            UD_locationId: Yup.string().required("Location is required"),
            UD_statusId: Yup.string().required("Status is required"),
            UD_roleID: Yup.string().required("Role is required"),
        }),
        onSubmit: (values) => {
            const formData = new FormData();

            if (!user_id) {
                for (const key in values) {
                    if (key !== "confirm_password" && key !== "paramId" && values.hasOwnProperty(key)) {
                        formData.append(key, values[key]);
                    }
                }
                user_registration(formData).then((response) => {
                    console.log(response)
                    if (response.status == 200) {
                        navigate(-1)
                        SuccessToast(response.data.message)
                    }
                }).catch((error) => {
                   
                    let message = "";
                    if(error.data.error){
                        message=error.data.error
                    }else{
                        for (const key in error.data) {
                            if (error.data.hasOwnProperty(key)) {
                                const keyMessages = error.data[key].map(message => message);
                                message = message.concat(keyMessages);
                            }
                        }
                    }
                    
                    ErrorToast(message)
                })
            } else {
                for (const key in values) {
                    if (key !== "confirm_password" && key !== "password" && key !== "paramId" && values.hasOwnProperty(key)) {
                        formData.append(key, values[key]);
                    }
                }
                formData.append('u_id', user_id);
                update_user_details(formData).then((response) => {
                    console.log(response)
                    if (response.status == 200) {
                        navigate(-1)
                        SuccessToast(response.data.message)
                    }
                }).catch((error) => {
                    console.log(error)
                    let message = "";
                    if(error.data.error){
                        message=error.data.error
                    }else{
                    for (const key in error.data.errors) {
                        if (error.data.errors.hasOwnProperty(key)) {
                            const keyMessages = error.data.errors[key].map(message => message);
                            message = message.concat(keyMessages);
                        }
                    }
                }
                    ErrorToast(message)
                })
            }
        }
    });
    const resetFormData = () => {
        formik.setValues({
            ...formik.values, ...{
                'UD_name': '',
                'UD_email': '',
                'password': '',
                "confirm_password": '',
                'UD_mobile': '',
                'UD_roleID': '',
                'UD_statusId': 1,
                'username': '',
                'UD_locationId': '',
                paramId: user_id || false,
            }
        })
    }
    return (
        <div className='user_form_super_container'>
            <div >
                <div className='user_form_sub_container'>
                    <div className='user_form_single_row_alignment'>
                        <IoArrowBackSharp className='user_form_back' onClick={() => {
                            navigate(-1)
                        }} />
                        <p className='user_form_title'>{user_id && isView ? "View" : user_id ? 'Update' : "Add New"} User</p>
                    </div>
                    {!isView && (
                        <div className='user_form_single_row_alignment'>
                            {!user_id && (
                                <button className='user_form_reset_button' onClick={() => {
                                    resetFormData()
                                }}>Reset</button>
                            )}
                            <button
                                className='user_form_save_button'
                                type="submit"
                                form="userform">Save</button>
                        </div>
                    )}

                </div>

                <div>
                    <form id="userform" onSubmit={formik.handleSubmit}>
                        <div className='user_form_main_container'>
                            <p className="user_form_legend">Personal Information</p>
                            <div className='user_form_sub_container_0'>
                                <div>
                                    <p className='user_form_label'>Name</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="UD_name"
                                        placeholder={"Enter name"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.UD_name}
                                        error={formik.touched.UD_name &&
                                            Boolean(formik.errors.UD_name)
                                        }
                                        helperText={
                                            formik.touched.UD_name && formik.errors.UD_name
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='user_form_label'>Email</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="UD_email"
                                        placeholder={"Enter email"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.UD_email}
                                        error={formik.touched.UD_email &&
                                            Boolean(formik.errors.UD_email)
                                        }
                                        helperText={
                                            formik.touched.UD_email && formik.errors.UD_email
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='user_form_label'>Mobile</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="UD_mobile"
                                        placeholder={"Enter mobile"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.UD_mobile}
                                        error={formik.touched.UD_mobile &&
                                            Boolean(formik.errors.UD_mobile)
                                        }
                                        helperText={
                                            formik.touched.UD_mobile && formik.errors.UD_mobile
                                        }
                                    />
                                </div>
                            </div>

                            {/* <div style={{marginTop:'5px'}}>
                                <p className='user_form_label'>Address</p>
                                <CustomTextArea
                                    rows={6}
                                    name="address"
                                    placeholder={"Enter address"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.address}
                                    error={formik.touched.address &&
                                        Boolean(formik.errors.address)
                                    }
                                    helperText={
                                        formik.touched.address && formik.errors.address
                                    }
                                />
                            </div> */}


                        </div>
                        <div className='user_form_main_container'>
                            <p className="user_form_legend">Official Informations</p>
                            <div className='user_form_sub_container_0'>
                                <div>
                                    <p className='user_form_label'>Role</p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="UD_roleID"
                                        placeholder={"Select a role"}
                                        options={role}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "UD_roleID",
                                                newValue ? newValue.value : ""
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            role.find((role) =>
                                                role.value ===
                                                formik.values.UD_roleID
                                            ) || null
                                        }
                                        error={
                                            formik.touched.UD_roleID &&
                                            Boolean(formik.errors.UD_roleID)
                                        }
                                        helperText={
                                            formik.touched.UD_roleID &&
                                            formik.errors.UD_roleID
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='user_form_label'>Location</p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="UD_locationId"
                                        placeholder={"Select a location"}
                                        options={locations}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "UD_locationId",
                                                newValue ? newValue.value : ""
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            locations.find((location) =>
                                                location.value ===
                                                formik.values.UD_locationId
                                            ) || null
                                        }
                                        error={
                                            formik.touched.UD_locationId &&
                                            Boolean(formik.errors.UD_locationId)
                                        }
                                        helperText={
                                            formik.touched.UD_locationId &&
                                            formik.errors.UD_locationId
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='user_form_label'>User Name</p>
                                    <CustomTextField
                                        isDisabled={user_id}
                                        name="username"
                                        placeholder={"Enter user name"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.username}
                                        error={formik.touched.username &&
                                            Boolean(formik.errors.username)
                                        }
                                        helperText={
                                            formik.touched.username && formik.errors.username
                                        }
                                    />
                                </div>
                                {!user_id && (
                                    <>

                                        <div>
                                            <p className='user_form_label'>Password</p>
                                            <CustomTextField
                                                name="password"
                                                type="password"
                                                placeholder={"Enter password"}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.password}
                                                error={formik.touched.password &&
                                                    Boolean(formik.errors.password)
                                                }
                                                helperText={
                                                    formik.touched.password && formik.errors.password
                                                }
                                            />
                                        </div>
                                        <div>
                                            <p className='user_form_label'>Confirm Password</p>
                                            <CustomTextField
                                                name="confirm_password"
                                                type="password"
                                                placeholder={"Enter confirm password"}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.confirm_password}
                                                error={formik.touched.confirm_password &&
                                                    Boolean(formik.errors.confirm_password)
                                                }
                                                helperText={
                                                    formik.touched.confirm_password && formik.errors.confirm_password
                                                }
                                            />
                                        </div>
                                    </>
                                )}

                                <div>
                                    <p className='user_form_label'>Status</p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="UD_statusId"
                                        placeholder={"Select a status"}
                                        options={state}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "UD_statusId",
                                                newValue ? newValue.value : ""
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            state.find((status) =>
                                                status.value ===
                                                formik.values.UD_statusId
                                            ) || null
                                        }
                                        error={
                                            formik.touched.UD_statusId &&
                                            Boolean(formik.errors.UD_statusId)
                                        }
                                        helperText={
                                            formik.touched.UD_statusId &&
                                            formik.errors.UD_statusId
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    )
}

export default UserForm