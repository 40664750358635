import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField';
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown';
import './flightTicketTableFilterStyle.css'
import { get_ecr_status, get_ticket_type, get_trip_type } from '../../../../../api/commonLookUpApis';
import { get_supplier_without_pagination } from '../../../../../api/supplierApis';
import { get_client_lists_without_pagination } from '../../../../../api/clientApis';
import CustomDatePicker from '../../../../../components/custom_date_picker/CustomDatePicker';
import dayjs from "dayjs";
import CustomDateRangePicker from '../../../../../components/custom_date_range_picker/CustomDateRangePicker';
import { useDispatch, useSelector } from 'react-redux';
import { updateSearchFilter } from '../../../../../store/process_ecr/flightTicketStore';
function FlightTicketTableFilter({ searchFilter }) {
    const ecr_filter = useSelector((state) => state.flightTicketStore.ecr_filter)
    const [ticketType, setTicketType] = useState([])
    const [tripType, setTripType] = useState([])
    const [supplier, setSupplier] = useState([])
    const [client, setClient] = useState([])
    const [ecrStatus, setEcrStatus] = useState([])
    const dispatch = useDispatch()
    useEffect(() => {
        getTicketTypeList()
        getTripTypeList()
        getSupplierList()
        getClientList()
        getEcrStatus()
        formik.setValues({ ...formik.values, ...ecr_filter })
    }, [])
    const formik = useFormik({
        initialValues: {
            'FBD_PNR': '',
            "FBD_ticket_type_id": '',
            "FBD_trip_type": '',
            'FBD_supplier_id': '',
            'FBD_client_id': '',
            'FBD_issue_start_date': '',
            'FBD_issue_end_date': '',
            'FBD_ECRstatusID': ''
        },
        onSubmit: (values) => {
            dispatch(updateSearchFilter({ ...values }))
            const valueDeepCopy = JSON.parse(JSON.stringify(values))
            if (valueDeepCopy.FBD_issue_start_date.length > 0) {
                valueDeepCopy.FBD_issue_start_date = new Date(valueDeepCopy.FBD_issue_start_date).toLocaleString().split(',')[0].replaceAll('/', '-')
            }
            if (valueDeepCopy.FBD_issue_end_date.length > 0) {
                valueDeepCopy.FBD_issue_end_date = new Date(valueDeepCopy.FBD_issue_end_date).toLocaleString().split(',')[0].replaceAll('/', '-')
            }
            searchFilter(valueDeepCopy);
        }
    });
    const getTicketTypeList = () => {
        get_ticket_type().then((response) => {
            const data = response?.data?.data?.map((item) => {
                return {
                    label: item.TT_type,
                    value: item.TT_id,
                };
            });
            setTicketType(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getTripTypeList = () => {
        get_trip_type().then((response) => {
            const data = response?.data?.data?.map((item) => {
                return {
                    label: item.TRIP_name,
                    value: item.TRIP_id,
                };
            });
            setTripType(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const getSupplierList = () => {
        get_supplier_without_pagination().then((response) => {
            const data = response?.data?.data.map((item) => {
                return {
                    label: item.SD_name,
                    value: item.SD_id,
                };
            });
            setSupplier(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const getClientList = () => {
        get_client_lists_without_pagination().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.CI_name,
                    value: item.CI_id,
                };
            });
            setClient(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const getEcrStatus = () => {
        get_ecr_status().then((response) => {
            const data = response?.data?.data?.map((item) => {
                return {
                    label: item.ESL_name,
                    value: item.ESL_id,
                };
            });
            setEcrStatus(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const resetFilter = () => {
        formik.setValues({
            ...formik.values, ...{
                'FBD_PNR': '',
                "FBD_ticket_type_id": '',
                "FBD_trip_type": '',
                'FBD_supplier_id': '',
                'FBD_client_id': '',
                'FBD_issue_start_date': '',
                'FBD_issue_end_date': '',
                'FBD_ECRstatusID': ''
            }
        })
        searchFilter(null)
    }
    const onChangeDateRange = (data) => {
        formik.setValues({
            ...formik.values, ...{
                'FBD_issue_start_date': data[0].toString(),
                'FBD_issue_end_date': data[1].toString(),
            }
        })
    }
    return (
        <div className='flight_ticket_filter_main_container'>
            <form id="flightTicketFilterform" onSubmit={formik.handleSubmit}>
                <div className='flight_ticket_filter_sub_container'>
                    <div>
                        <CustomTextField
                            name="FBD_PNR"
                            placeholder={"Enter pnr number"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.FBD_PNR}
                        />
                    </div>
                    <div>
                        <CustomAutoCompleteDropdown
                            name="FBD_ticket_type_id"
                            placeholder={"Select a ticket type"}
                            options={ticketType}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "FBD_ticket_type_id",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                ticketType.find((type) =>
                                    type.value ===
                                    formik.values.FBD_ticket_type_id
                                ) || null
                            }
                        />
                    </div>
                    <div>
                        <CustomAutoCompleteDropdown
                            name="FBD_trip_type"
                            placeholder={"Select a trip type"}
                            options={tripType}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "FBD_trip_type",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                tripType.find((type) =>
                                    type.value ===
                                    formik.values.FBD_trip_type
                                ) || null
                            }
                        />
                    </div>
                    <div>
                        <CustomAutoCompleteDropdown
                            name="FBD_ECRstatusID"
                            placeholder={"Select a status"}
                            options={ecrStatus}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "FBD_ECRstatusID",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                ecrStatus.find((type) =>
                                    type.value ===
                                    formik.values.FBD_ECRstatusID
                                ) || null
                            }
                        />
                    </div>
                    <div>
                        <CustomAutoCompleteDropdown
                            name="FBD_supplier_id"
                            placeholder={"Select a supplier"}
                            options={supplier}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "FBD_supplier_id",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                supplier.find((supplier) =>
                                    supplier.value ===
                                    formik.values.FBD_supplier_id
                                ) || null
                            }
                        />
                    </div>
                    <div>
                        <CustomAutoCompleteDropdown
                            name="FBD_client_id"
                            placeholder={"Select a client"}
                            options={client}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "FBD_client_id",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                client.find((client) =>
                                    client.value ===
                                    formik.values.FBD_client_id
                                ) || null
                            }
                        />
                    </div>
                    <div>
                        <CustomDateRangePicker selected_dates={[formik.values.FBD_issue_start_date, formik.values.FBD_issue_end_date]} onChange_date={onChangeDateRange} />
                    </div>
                    <div className='flight_ticket_filter_actions'>
                        <button className='flight_ticket_filter_action_reset' onClick={() => {
                            resetFilter()
                        }}>Reset</button>
                        <button className='flight_ticket_filter_action_search'
                            type="submit"
                            form="flightTicketFilterform"
                        >Search</button>
                    </div>
                </div>

            </form>
        </div>
    )
}

export default FlightTicketTableFilter