import React, { useEffect, useState } from 'react'
import { FaAngleDown } from "react-icons/fa6";
import { CiMenuBurger } from "react-icons/ci";
import './headerStyle.css'
import profile_icon from '../../../assets/profile_icon.png'
import change_password_icon from '../../../assets/change_password_icon.png'
import logout_icon from '../../../assets/logout_icon.png'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateCollapsedState } from '../../../store/commonStore';
import { LocalStorageServices } from '../../../services/localStorageServices';
import { user_login, user_logout } from '../../../api/authApis';
import { Avatar } from '@mui/material';
import { resetSearchFilter } from '../../../store/process_ecr/flightTicketStore';
function Header() {
  const [isShowProfileMenu, setIsShowProfileMenu] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [userDetails, setUserDetails] = useState(null)
  useEffect(() => {
    const user_details = LocalStorageServices.getUserDetailsLocalStorageData()
    setUserDetails(user_details ? JSON.parse(user_details) : user_details)
  }, [])
  return (
    <div className='header_main_container'>
      <div>
        <CiMenuBurger className='header_hamburger_menu' onClick={() => {
          dispatch(updateCollapsedState())
        }} />
      </div>
      <div className='header_profile_main_container'>
        <div>
          <Avatar alt="Remy Sharp" src={``}>
            {userDetails?.username?.charAt(0)}
          </Avatar>
        </div>
        <div className='header_user_details' onClick={() => {
          setIsShowProfileMenu(!isShowProfileMenu)
        }}>
          <p className='header_user_name'>{userDetails?.username}</p>
          <div className='header_role_dropdown_container'>
            <p className='header_role'>{userDetails?.role?.role_name}</p>
            <FaAngleDown className='header_dropdown' />
          </div>

        </div>

      </div>
      {isShowProfileMenu && (
        <div className='header_profile_based_menus'>
          <div className='header_profile_based_menus_container'>
            <img src={profile_icon} className='header_profile_icon' />
            <p className='header_profile_text'>Edit Profile</p>
          </div>
          <div className='header_profile_based_menus_container'>
            <img src={change_password_icon} className='header_profile_icon' />
            <p className='header_profile_text'>Change Password</p>
          </div>
          <div className='header_profile_based_menus_container' onClick={() => {
            dispatch(resetSearchFilter())
            navigate('/ecrs_portal/login')
            LocalStorageServices.deleteLocalStorageData()
            user_logout().then((response) => {
              console.log(response)
            }).catch((error) => {
              console.log(error)
            })

          }}>
            <img src={logout_icon} className='header_profile_icon' />
            <p className='header_profile_text'>Logout</p>
          </div>
        </div>

      )}

    </div>
  )
}

export default Header