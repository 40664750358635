import React, { useEffect, useState } from 'react'
import './clientStyle.css'
import { useNavigate } from 'react-router-dom'
import CustomTable from '../../../../components/custom_table/CustomTable'
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { PiEye } from "react-icons/pi";
import { delete_client, get_client_lists, get_client_lists_with_pagination } from '../../../../api/clientApis';
import { ErrorToast, SuccessToast } from '../../../../toast/toasts';
import CustomBottomPagination from '../../../../components/custom_bottom_pagination/CustomBottomPagination';
import ClientFilter from './client_filter/ClientFilter';
import { LocalStorageServices } from '../../../../services/localStorageServices';

function Clients() {
    const baseRouteUrl=`/ecrs_portal/${LocalStorageServices.getUserRole()}/master_settings`
    const navigate=useNavigate()
    const [clientListing,setClientListing]=useState([])
    const [pageNumber,setPageNumber]=useState(1)
    const [totalPages,setTotalPages]=useState(0)
    const [totalItemPerPage,setTotalItemPerPage]=useState(10)
    const [searchFilter,setSearchFilter]=useState(null)
    useEffect(()=>{
      get_clients(pageNumber,searchFilter)
    },[pageNumber,totalItemPerPage])
    const get_clients=(pageNumber,filterValue)=>{
      const formData = new FormData();
      formData.append('page', pageNumber);
      formData.append('pageItem_count',totalItemPerPage);
      if(filterValue){
        for (const key in filterValue) {
          formData.append(key, filterValue[key]);
      }
      }
      get_client_lists_with_pagination(formData).then((response)=>{
        setClientListing(response.data.data)
        setTotalPages(response.data.last_page)
      }).catch((error)=>{
        let message = "";
        for (const key in error.data.errors) {
          if (error.data.errors.hasOwnProperty(key)) {
            const keyMessages = error.data.errors[key].map(message => message);
            message = message.concat(keyMessages);
          }
        }
        ErrorToast(message)
      })
    }
    const Columns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key",
        
      },
      {
        title: "Clients Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Phone Number",
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        wrapper: (row) => (
          <div >
             <p style={{color:row.status=='Active'?'#0A9E02':"#FF0000"}}>{row.status}</p>
          </div>
        ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        action: (row) => (
          <div  style={{ display: 'flex', justifyContent:'flex-start', alignItems: 'center', gap: 10 }}>
             <PiEye className='user_action_view' onClick={()=>{
             navigate(baseRouteUrl+`/${row.id}/view_client`)
           }}/> 

            <BiEditAlt className='user_action_edit' onClick={()=>{
              navigate(baseRouteUrl+`/${row.id}/update_client`)
            }} />
            <MdOutlineDeleteOutline className='user_action_delete' onClick={()=>{
              deleteClient(row.id)
            }} />
          </div>
        ),
      },
    ];
    
    const datas = () => {
      const result =
        clientListing &&
        clientListing.map((item, index) => {
          return {
            key:pageNumber * totalItemPerPage - (totalItemPerPage - (index + 1)),
            id: item.CI_id,
            name: item.CI_name,
            email: item.CI_email,
            phone:item.CI_phone,
            status:item.status_name,
          };
        });
      return result;
    };
    const updatePagination=(pageNumber)=>{
      setPageNumber(pageNumber)
    }
    const updateItemPageCount=(pageItemCount)=>{
      setTotalItemPerPage(pageItemCount)
    }
    const deleteClient=(client_id)=>{
      delete_client(client_id).then((response)=>{
        if(response.status==200){
          SuccessToast(response.data.message)
          get_clients(pageNumber)
        }
      }).catch((error)=>{
        console.log(error)
      })
    }
    const searchfilter=(value)=>{
      setSearchFilter(value)
      if(value){
        get_clients(pageNumber,value)
      }else{
        get_clients(1,value)
        setPageNumber(1)
      }
      
  
    }
    return (
      <div className='clients_module_main_container'>
        <p className='clients_title'>Clients</p>
        <div className='client_module_filter'>
         <ClientFilter searchFilter={searchfilter}/>
          <button className='client_add_button' onClick={()=>{
            navigate(baseRouteUrl+`/create_new_client`)
          }}>New</button>
        </div>
        <div className='client_table_main_container'>
          <CustomTable columns={Columns} rows={datas()}/>
        </div>
        <div>
         <CustomBottomPagination total_items_per_page={totalItemPerPage} total_page_count={totalPages} current_page={pageNumber} on_change_value={updatePagination} on_page_count_update={updateItemPageCount}/>
      </div>
      </div>
    )
}

export default Clients