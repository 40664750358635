import { Autocomplete, Paper, styled, TextField } from '@mui/material'
import React from 'react'

function CustomAutoCompleteDropdown({ name, placeholder, options, value, onChange, onBlur, error, helperText ,isDisabled}) {
  const ModifiedAutoComplete = {
    '& .MuiOutlinedInput-root': {
      fontFamily: 'Poppins',
      fontSize:'15px',
      '& fieldset': {
        borderColor: '#C0C0C0',
      },
      '&:hover fieldset': {
        borderColor: '#C0C0C0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#C0C0C0',
      },
    },
    "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#272727",
        },
  };
  return (
    <div>
      <Autocomplete
        disabled={isDisabled}
        name={name}
        options={options}
        componentsProps={{
          paper: { sx: { wordBreak: "break-word" } },
        }}
        getOptionLabel={(option) => option.label}
        fullWidth
        autoComplete={false}
        ListboxProps={{ style: { minHeight: 0 } }}
        menuprops={{ style: { minHeight: 0 } }}
        PaperComponent={(props) => (
          <Paper
            {...props}
          />
        )}
        className="user_form_autoinput"
        size="small"
        renderInput={(params) => (
          <TextField
            size="small"
            {...params}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            FormHelperTextProps={{
              style: { marginLeft: 0 },
            }}
          />
        )}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        sx={ModifiedAutoComplete}
      />
    </div>
  )
}

export default CustomAutoCompleteDropdown