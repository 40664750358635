import React from 'react'
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Table } from "@mui/material";
import table_no_data from '../../assets/table_not_data.png'
import './customTableColumnWithEditableRow.css'
function CustomTableColumnWithEditableRow({ columns, rows, no_border,checkItems }) {
    const StyledTableCell = styled(TableCell)(({ isAction }) => ({
        ...(isAction && {
            textAlign: 'center',
        }),
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#38818133",
            color: "#000000",
            borderBottom: "none",
            fontWeight: "600",
            fontFamily: "Jost",
            fontSize: "14px",
            padding: "15px 12px",
        },
        [`&.${tableCellClasses.body}`]: {
            maxWidth: "500px",
            overflowWrap: "break-word",
            fontSize: "14px",
            borderBottom: "none",
            fontFamily: "Jost",
            padding: "12px 15px",
            overflow: "auto",
            whiteSpace: "normal",
            wordWrap: "break-word",
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: "#ffffff",
            "& > *:first-of-type": {
                backgroundColor: "#ffffff",
            },
        },
        "&:nth-of-type(even)": {
            backgroundColor: "#f2f6f6",
            "& > *:first-of-type": {
                backgroundColor: "#f2f6f6",
            },
        },
    }));
    const StyledTableRowWithBorderRadius = styled(TableRow)(({ theme }) => ({
        '&:first-of-type th:first-of-type': {
            borderTopLeftRadius: no_border ? '0px' : '10px',
            borderBottomLeftRadius: no_border ? '0px' : '10px',
        },
        '&:first-of-type th:last-of-type': {
            textAlign: columns.length >= 5 ? 'left' : 'center',
            borderTopRightRadius: no_border ? '0px' : '10px',
            borderBottomRightRadius: no_border ? '0px' : '10px',
        },
    }));
    return (
        <>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead style={{ borderRadius: "20px" }}>
                        <StyledTableRowWithBorderRadius>
                            {columns &&
                                columns.map((column) => (
                                    <StyledTableCell
                                        key={column.key}
                                        isAction={!!column.actions}
                                    >
                                        {column.key === "header_checkbox" ? column.action(rows) : column.title}
                                    </StyledTableCell>
                                ))}
                        </StyledTableRowWithBorderRadius>
                    </TableHead>
                    <TableBody>
                        {rows &&
                            rows.map((row,index) => (
                                <StyledTableRow key={row.key} style={{backgroundColor:checkItems.includes(index+1)?'#f7f7f7':''}}>
                                    {columns.map((column) => (
                                        <StyledTableCell
                                            key={column.key}
                                            style={{backgroundColor:checkItems.includes(index+1)?'#f7f7f7':''}}
                                        >
                                            {column.action ? (
                                                column.action(row)
                                            ) : column.editableField ? (
                                                column.editableField(row)
                                            ) : column.wrapper ? (
                                                column.wrapper(row)
                                            ) : (
                                                <div>
                                                    <p>
                                                        {row[column.dataIndex] ? row[column.dataIndex] : " - "}
                                                    </p>
                                                </div>
                                            )}
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {rows && rows.length === 0 && (
                <div className="no_data_container">
                    <div className="no_data_sub_container">
                        <img src={table_no_data} className='no_data_image' />
                    </div>
                </div>
            )}</>
    )
}

export default CustomTableColumnWithEditableRow