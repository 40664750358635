import axios from 'axios'
import { LocalStorageServices } from '../services/localStorageServices';
import { ErrorToast } from '../toast/toasts';

// const apiBaseUrl='Ecr-version-2024-api/public/api/'  //Local
const apiBaseUrl='public_html/public/api/' //Production
const ApiConfig= axios.create({
    baseURL:`${process.env.REACT_APP_BASEURL + apiBaseUrl }`,
    maxContentLength: Infinity,
})

ApiConfig.interceptors.request.use(
    (config) => {
        const accessToken =LocalStorageServices.getAccessTokenLocalStorageData()
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

ApiConfig.interceptors.response.use(
    (response) => {
        const modifiedResponse = {
        data: response.data,
        status: response.status,
        };             


    return modifiedResponse;
    },
    (error) => {
        if (error.response) {
            const status = error.response.status;
            if (status === 401) {
                ErrorToast("UnAuthorized")
                setTimeout(() => {
                    window.location.href='/ecrs_portal/login'
                }, 1000);
            } else if (status === 500) {
                // ErrorToast("Internal server error")
            }
           
        }
        if(error.message=="Network Error"){
            ErrorToast("Network Error")
        }
        return Promise.reject(error.response);
    }
);
export {ApiConfig}


