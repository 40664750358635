import React from 'react'
import CustomAutoCompleteDropdown from '../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown'
import './privilageStyles.css'
function Privilages() {
  return (
    <div className='privilages_module_main_container'>
      <div className='privilages_module_sub_container_0'>
        <p className='privilages_title'>Privilages</p>
        <div className='privilage_form_single_row_alignment'>
          <button className='privilage_form_reset_button'>Reset</button>
          <button className='privilage_form_save_button'>Save</button>
        </div>
      </div>
      <div className='privilages_module_sub_container_1'>
        <p className="privilage_form_main_legend">General</p>
        <div className='privilages_module_sub_container_2'>
          <p className='privilage_form_label'>Role</p>
          <CustomAutoCompleteDropdown
            name="role"
            placeholder={"Select a role"}
            options={[
              { label: 'Active', year: 1994 },
              { label: 'In Active', year: 1972 }]}
          />
        </div>
        <div className='privilage_module_wise_main_container'>
           <div className='privilage_module_wise_container'>
             <p className='privilage_sub_legend'>Process Ecr</p>
             <div className='privilage_actions_container'>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>View</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Add</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Edit</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Delete</p>
                </div>
             </div>
           </div>
           <div className='privilage_module_wise_container'>
             <p className='privilage_sub_legend'>User Management</p>
             <div className='privilage_actions_container'>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>View</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Add</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Edit</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Delete</p>
                </div>
             </div>
           </div>
           <div className='privilage_module_wise_container'>
             <p className='privilage_sub_legend'>Reports</p>
             <div className='privilage_actions_container'>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>View</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Add</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Edit</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Delete</p>
                </div>
             </div>
           </div>
           <div className='privilage_module_wise_container'>
             <p className='privilage_sub_legend'>Payment</p>
             <div className='privilage_actions_container'>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>View</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Add</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Edit</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Delete</p>
                </div>
             </div>
           </div>
           <div className='privilage_module_wise_container'>
             <p className='privilage_sub_legend'>Address Book</p>
             <div className='privilage_actions_container'>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>View</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Add</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Edit</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Delete</p>
                </div>
             </div>
           </div>
           <div className='privilage_module_wise_container'>
             <p className='privilage_sub_legend'>Services</p>
             <div className='privilage_actions_container'>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>View</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Add</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Edit</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Delete</p>
                </div>
             </div>
           </div>
           <div className='privilage_module_wise_container'>
             <p className='privilage_sub_legend'>Supplier</p>
             <div className='privilage_actions_container'>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>View</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Add</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Edit</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Delete</p>
                </div>
             </div>
           </div>
           <div className='privilage_module_wise_container'>
             <p className='privilage_sub_legend'>Tax-GST</p>
             <div className='privilage_actions_container'>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>View</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Add</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Edit</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Delete</p>
                </div>
             </div>
           </div>
           <div className='privilage_module_wise_container'>
             <p className='privilage_sub_legend'>Tax-TCS</p>
             <div className='privilage_actions_container'>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>View</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Add</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Edit</p>
                </div>
                <div className='privilage_actions_sub_container'>
                  <input type="checkbox" id="" name="" />
                  <p>Delete</p>
                </div>
             </div>
           </div>
        </div>
      </div>
    </div>
  )
}

export default Privilages