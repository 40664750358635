import React, { useEffect, useState } from 'react'
import { IoAddOutline } from "react-icons/io5";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import CustomTable from '../../../../../components/custom_table/CustomTable';
import './flightTicketPassengerTable.css'
import CustomModal from '../../../../../components/custom_modal/CustomModal';
import FlightTicketPassengerForm from '../flight_ticket_passenger_form/FlightTicketPassengerForm';
import { IconButton } from '@mui/material';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useLocation, useParams } from 'react-router-dom';
import { get_cabin_types, get_passenger_type_lists, get_titles_lists } from '../../../../../api/lookupApis';
import { GoDotFill } from "react-icons/go";
function FlightTicketPassengerTable({ passengerCount, passengerOtherCount, passengerList, setPassengerList, updatePassengerDetails, passengerDelete }) {
    let { booking_id } = useParams();
    const location = useLocation()
    const [isView, setIsView] = useState(null)
    const [open, setOpen] = useState(false);
    const [passengerDetails, setPassengerDetails] = useState(null)
    const [passengerType, setPassengerType] = useState([])
    const [passengerTitle, setPassengerTitle] = useState([])
    const [cabinType, setCabinType] = useState([])
    useEffect(() => {
        if (location.pathname.split('/').pop() === "view_flight_ticket") {
            setIsView(true)
        }
        getPassengerList()
        getPassengerTitle()
        getPassengerCabin()
    }, [passengerList])
    const getPassengerList = () => {
        get_passenger_type_lists().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.PTL_name,
                    value: item.PTL_id,
                };
            });
            setPassengerType(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getPassengerTitle = () => {
        get_titles_lists().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.TL_name,
                    value: item.TL_id,
                };
            });
            setPassengerTitle(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getPassengerCabin = () => {
        get_cabin_types().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.CTL_name,
                    value: item.CTL_id,
                };
            });
            setCabinType(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const Columns = [
        {
            title: "Sl No",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "Cabin",
            dataIndex: "cabin_name",
            key: "cabin_name",

        },
        {
            title: "Type",
            dataIndex: "type_name",
            key: "type_name",
        },

        {
            title: "Ticket Number",
            dataIndex: "ticket_number",
            key: "ticket_number",
        },
        {
            title: "Full Name",
            dataIndex: "full_name",
            key: "full_name",
        },
        {
            title: "Base Fare",
            dataIndex: "base_fare",
            key: "base_fare",
        },
        {
            title: "JN Tax",
            dataIndex: "jn_tax",
            key: "jn_tax",
        },
        {
            title: "YQ Tax",
            dataIndex: "yq_tax",
            key: "yq_tax",
        },
        {
            title: "OC Tax",
            dataIndex: "oc_tax",
            key: "oc_tax",
        },
        {
            title: "Other Tax",
            dataIndex: "other_tax",
            key: "other_tax",
        },
        {
            title: "Supplier Amount",
            dataIndex: "supplier_amount",
            key: "supplier_amount",
        },
        {
            title: "Service Fee",
            dataIndex: "service_fee",
            key: "service_fee",
        },
        {
            title: "Customer Net",
            dataIndex: "customer_net",
            key: "customer_net",
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            hide: isView,
            action: (row) => (
                <div style={{ display: 'flex', alignItems: 'center', 'gap': 5 }}>
                    <BiEditAlt className='flight_ticket_passenger_table_action_edit' onClick={() => {
                        setPassengerDetails(row)
                        setOpen(true)
                    }} />
                    <MdOutlineDeleteOutline className='flight_ticket_passenger_table_action_delete' onClick={() => {
                        passengerDelete(row.index)
                    }} />
                </div>
            ),
        },
    ];

    const handleClose = (is_close) => {
        if (!is_close) {
            setPassengerDetails(null)
        }
        setOpen(is_close);
    };
    const set_passenger_details = (details) => {
        setPassengerList(details)
    }
    const update_passenger_details = (details) => {
        updatePassengerDetails(details)
    }
    const datas = () => {
        const result =
            passengerList &&
            passengerList.map((item, index) => {
                const passengerTypeItem = passengerType.find(pt => pt.value == item.FBPD_Passanger_type)?.label;
                const passengerCabinType = cabinType.find(pt => pt.value == item.FBPD_cabin_type)?.label;
                return {
                    key: index + 1,
                    index: index,
                    type: item.FBPD_Passanger_type,
                    type_name: passengerTypeItem,
                    title: item.FBPD_title_id,
                    ticket_number: item.FBPD_ticket_number,
                    full_name: item.FBPD_FullName,
                    base_fare: Number(item.FBPD_base_fare),
                    jn_tax: Number(item.FBPD_jn_tax),
                    yq_tax: Number(item.FBPD_yq_tax),
                    oc_tax: Number(item.FBPD_oc_tax),
                    other_tax: Number(item.FBPD_other_tax),
                    service_fee: Number(item.FBPD_service_fee),
                    supplier_amount: Number(item.FBPD_supplier_amount),
                    customer_net: Number(item.FBPD_customer_net),
                    cabin_name: passengerCabinType,
                    cabin_id: item.FBPD_cabin_type,
                    is_highlight: item?.FBPD_cancel_status ? true : false
                };
            });
        return result;
    };
    return (
        <div>
            <CustomModal
                maxWidth={"1500px"}
                open={open}
                handleClose={handleClose}
                close={<IconButton
                    aria-label="close"
                    onClick={() => {
                        handleClose(false)
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                > <IoIosCloseCircleOutline />
                </IconButton>}
                body={
                    <FlightTicketPassengerForm totalPassenger={passengerList} passengerOtherCounts={passengerOtherCount} existingPassengerDetails={passengerDetails} onClose={handleClose} setPassengerDetails={set_passenger_details} updatePassengerDetails={update_passenger_details} />
                }
                title={!passengerDetails ? "Add Passenger" : "Update Passenger"}
            />
            <div className='table_legent_main_container'>
                <GoDotFill className='table_legent_cancel_icon' />
                <p className='table_legent_cancel_text'>Cancelled</p>
            </div>
            <div className='flight_ticket_passenger_table_header_component'>
                <p className='flight_ticket_passenger_table_header_text'>Passenger Details</p>
                {!isView && passengerCount != 0 && passengerCount > 0 && passengerList.length != passengerCount && (
                    <div className='flight_ticket_passenger_table_header_action_container' onClick={() => {
                        setOpen(true)
                    }}>
                        <IoAddOutline className='flight_ticket_passenger_table_action_add' />
                        <p className='flight_ticket_passenger_table_action_text'>Add Passenger</p>
                    </div>
                )}

            </div>

            <div>
                <CustomTable columns={Columns.filter((item) => item.hide !== true)} rows={datas()} no_border={true} />
            </div>

        </div>
    )
}

export default FlightTicketPassengerTable