import { ApiConfig } from "./apiConfig";

export const get_client_lists_with_pagination = async (data) => {
    try {
      const response = await ApiConfig.post(`clients_List`,data, { headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};

export const get_client_lists_without_pagination = async () => {
  try {
    const response = await ApiConfig.post(`clients_List`,{ headers: { "Content-Type": "multipart/form-data" }});
    return response;
  } catch (error) {
    throw error;
  }
}
export const get_client_by_id = async (client_id) => {
    try {
      const response = await ApiConfig.post(`clientByid`,{client_id:client_id}, { headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};

export const client_registration = async (data) => {
    try {
      const response = await ApiConfig.post(`client_register`,data, { headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};

export const update_client_details = async (client_id,data) => {
    try {
      const response = await ApiConfig.post(`clientupdate/${client_id}`,data, { headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};

export const delete_client = async (client_id) => {
    try {
      const response = await ApiConfig.delete(`clientDelete/${client_id}`,{ headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};