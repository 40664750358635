import React, { useEffect, useState } from 'react'
import CustomTable from '../../../../../../components/custom_table/CustomTable'
import './flightTicketCancelConfirmation.css'
import { flight_ticket_cancel } from '../../../../../../api/process_ecr/flightTicketApis'
import { SuccessToast } from '../../../../../../toast/toasts'
function FlightTicketCancelConfirmation({ formDetails, amount, handleCloseCancelConfirmModal, handleCloseAllConfirmModal }) {
  const [passengerCounts, setPassengerCounts] = useState({
    'adult': 0,
    'child': 0,
    'infants': 0
  })
  useEffect(() => {
    if (formDetails?.passengerData) {
      passengerCount()
    }
  }, [formDetails?.passengerData])
  const Columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",

    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Passenger Type",
      dataIndex: "passenger_type",
      key: "passenger_type",
    },
    {
      title: "Ticket Number",
      dataIndex: "ticket_number",
      key: "ticket_number",
    },
    {
      title: "Current Supplier Fee",
      dataIndex: "supplier_amount",
      key: "supplier_amount",
    },
    {
      title: "Current Service Fee",
      dataIndex: "service_charge",
      key: "service_charge",
    },
    {
      title: "Current Customer Net",
      dataIndex: "customer_net",
      key: "customer_net",
    },
    {
      title: "Cancel Supplier Fee",
      dataIndex: "airline_supplier_fee",
      key: "airline_supplier_fee",
    },
    {
      title: "Cancel Service Fee",
      dataIndex: "las_service_fee",
      key: "las_service_fee",
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
    },
  ]
  const datas = () => {
    const result =
      formDetails?.passengerData &&
      formDetails?.passengerData.map((item, index) => {
        return {
          key: index + 1,
          id: item.id,
          name: item.name,
          passenger_type: item.passenger_type,
          ticket_number: item.ticket_number,
          supplier_amount: item.supplier_amount,
          service_charge: item.service_charge,
          customer_net: item.customer_net,
          airline_supplier_fee:item.airline_supplier_fee?item.airline_supplier_fee:'0',
          las_service_fee:item.las_service_fee?item.las_service_fee:"0",
          gst:item.gst?item.gst:"0"
        };
      });
    return result;
  };
  const passengerCount = () => {
    let adultCount = 0;
    let childCount = 0;
    let infantCount = 0;

    formDetails?.passengerData?.forEach((item) => {
      if (item.passenger_type === 1) adultCount++;
      else if (item.passenger_type === 2) childCount++;
      else if (item.passenger_type === 3) infantCount++;
    });

    setPassengerCounts({
      adult: adultCount,
      child: childCount,
      infants: infantCount,
    });
  }
  const onConfirmCancel=()=>{
    const reStructureResponseData=[]
    formDetails?.passengerData.map((item)=>{
      const reStructure={
          "bookingId": formDetails?.basicDetails?.booking_id, 
          "FBCD_passenger_id": item.id, 
          "PNR_Number":formDetails?.basicDetails?.pnr_number,
          "FBCD_supplier_fee":item.airline_supplier_fee,
          "FBCD_service_fee":item.las_service_fee,
          "FBCD_gst":item.gst,
          "FBCD_comment":formDetails?.basicDetails?.remark,
          "FBCD_cancel_date":formDetails?.basicDetails?.date_of_cancellation
      }
      reStructureResponseData.push(reStructure)
    })
    flight_ticket_cancel(reStructureResponseData).then((response)=>{
      SuccessToast("Sucesssfully cancelled ticket")
      handleCloseAllConfirmModal(false)
    }).catch((error)=>{
      console.log(error)
    })
  }
  return (
    <div>
      <div className='flight_cancel_confirm_main_container'>
        <p className="flight_cancel_confirm_main_legend">Confirmation Details</p>
        <div >
          <div className='flight_cancel_confirm_passenger_count'>
            <p>Adult(s) : {passengerCounts.adult}</p>
            <p>Child(s) : {passengerCounts.child}</p>
            <p>Infants(s) : {passengerCounts.infants}</p>
          </div>
          <CustomTable columns={Columns} rows={datas()} />
          <div>
            <p className='flight_cancel_confirm_passenger_narration'>Remarks</p>
            <p className='flight_cancel_confirm_text_1'>{formDetails?.basicDetails?.remark}</p>
          </div>
        </div>
      </div>
      <div className='flight_cancel_confirm_main_container'>
        <p className="flight_cancel_confirm_main_legend">Fare Details</p>
        <div >
          <div>
            <div className="flight_cancel_confirm_main_fair_container">
              <p className='flight_cancel_confirm_text_1'>Date Of Cancellation</p>
              {/* <div className='dummy_border_line' /> */}
              <p className='flight_cancel_confirm_value_text_1'>{formDetails?.basicDetails?.date_of_cancellation}</p>
            </div>

            <div className="flight_cancel_confirm_main_fair_container">
              <p className='flight_cancel_confirm_text_1'>No of Passengers</p>
              {/* <div className='dummy_border_line' /> */}
              <p className='flight_cancel_confirm_value_text_1'>{formDetails?.passengerData?.length}</p>
            </div>
            <div className="flight_cancel_confirm_main_fair_container">
              <p className='flight_cancel_confirm_text_2'>Total Amount</p>
              {/* <div className='dummy_border_line' /> */}
              <p className='flight_cancel_confirm_value_text_2'>{formDetails?.amount?.totalAmount}</p>
            </div>

            <div className="flight_cancel_confirm_main_fair_container">
              <p className='flight_cancel_confirm_text_2'>Cancellation Fee</p>
              {/* <div className='dummy_border_line' /> */}
              <p className='flight_cancel_confirm_value_text_2'>{formDetails?.amount?.cancellationFee}</p>
            </div>

            <div className="flight_cancel_confirm_main_fair_container">
              <p className='flight_cancel_confirm_text_2'>Refund Amount</p>
              {/* <div className='dummy_border_line' /> */}
              <p className='flight_cancel_confirm_value_text_2'>{formDetails?.amount?.refundAmount}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='flight_cancel_confirm_single_row_alignment'>
        <button className='flight_cancel_confirm_close_button' onClick={() => {
          handleCloseCancelConfirmModal(false)
        }} >Back</button>
        <button
          onClick={() => {
            onConfirmCancel()
          }}
          className='flight_cancel_confirm_save_button'
        >Proceed</button>
      </div>
    </div>

  )
}

export default FlightTicketCancelConfirmation