import React, { useState } from 'react'
import CustomModal from '../../../../../../components/custom_modal/CustomModal'
import FlightDateChangeForm from '../flight_date_change_form/FlightDateChangeForm'

function FlightDateChangeModals({details,open,onClose,handleDateChangePassenger}) {
  const [passengerDateChnage,setPassengerDateChange]=useState(null)

  return (
    <div>
        <CustomModal
                maxWidth={"1000px"}
                open={open}
                handleClose={onClose}
                body={
                   <FlightDateChangeForm selected_item={details} on_modal_close={onClose} handlePassengerOnChnage={handleDateChangePassenger}/>
                }
                title={"Date Reschedule"}
            />
    </div>
  )
}

export default FlightDateChangeModals