import React, { useEffect } from 'react'
import './flightTicketStyle.css'
import { BiEditAlt } from "react-icons/bi";
import { PiEye } from "react-icons/pi";
import { IoCloseCircleOutline } from "react-icons/io5";
import { RiLoopLeftFill } from "react-icons/ri";
import CustomTable from '../../../../components/custom_table/CustomTable';
import { useNavigate } from 'react-router-dom';
import { LocalStorageServices } from '../../../../services/localStorageServices';
import { useState } from 'react';
import { delete_booked_ticket, get_flight_ticket_with_pagination } from '../../../../api/process_ecr/flightTicketApis';
import { ErrorToast, SuccessToast } from '../../../../toast/toasts';
import CustomBottomPagination from '../../../../components/custom_bottom_pagination/CustomBottomPagination';
import { MdDeleteOutline } from "react-icons/md";
import FlightTicketTableFilter from './flight_ticket_table_filter/FlightTicketTableFilter';
import CustomTableColumnSelector from '../../../../components/custom_table_column_selector/CustomTableColumnSelector';
import { useDispatch, useSelector } from 'react-redux';
import { resetSearchFilter, updateColumCheckList } from '../../../../store/process_ecr/flightTicketStore';
import FlightTicketModals from './flight_ticket_modals/FlightTicketModals';
import { TiTickOutline } from "react-icons/ti";
import FlightTicketManagerLevelModal from './flight_ticket_manage_level_modals/FlightTicketManagerLevelModal';
import { IconButton, Tooltip } from '@mui/material';
import { FaRegCommentAlt } from "react-icons/fa";
import FlightTicketCommentModal from './flight_ticket_comment_modal/FlightTicketCommentModal';
import { FaRegCirclePause } from "react-icons/fa6";
import { SiTicktick } from "react-icons/si";
import FlightTicketFinanceLevelModals from './flight_ticket_finance_level_modals/FlightTicketFinanceLevelModals';
import FlightTicketCfoLevelModal from './flight_ticket_cfo_level_modals/FlightTicketCfoLevelModal';
import { LiaFileInvoiceSolid } from "react-icons/lia";
import FlightTicketInvoiceDetailsModal from './flight_ticket_invoice_details_modal/FlightTicketInvoiceDetailsModal';
function FlightTickets() {
  const {defaultColumnChecked,ecr_filter} = useSelector((state) => state.flightTicketStore)
  const dispatch = useDispatch()
  const [cancelModal, setCancelModal] = useState(false);
  const [dateChangeModal, setDateChangeModal] = useState(false);
  const [flightsListing, setFlightsListing] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [totalItemPerPage, setTotalItemPerPage] = useState(10)
  const [searchFilter, setSearchFilter] = useState(null)
  const baseRouteUrl = `/ecrs_portal/${LocalStorageServices.getUserRole()}/process_ecr`
  const [userDetails, setUserDetails] = useState(null)
  const [actionModal, setActionModal] = useState(false)
  const [commentModal, setcommentModal] = useState(false)
  const [financeLevelModal, setfinanceLevelModal] = useState(false)
  const [cfoLevelModal, setCfoLevelModal] = useState(false)
  const [invoiceDetailsModal, setInvoiceDetailsModal] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const user_details = LocalStorageServices.getUserDetailsLocalStorageData()
    setUserDetails(user_details ? JSON.parse(user_details) : user_details)
    get_booking_list(pageNumber, ecr_filter)
  }, [pageNumber, totalItemPerPage, actionModal, financeLevelModal, cfoLevelModal, cancelModal])

  const get_booking_list = (pageNumber, filterValue) => {
    const formData = new FormData();
    formData.append('page', pageNumber);
    formData.append('pageItem_count', totalItemPerPage);
    if (filterValue) {
      for (const key in filterValue) {
        if (filterValue[key]) {
          formData.append(key, filterValue[key]);
        }

      }
    }
    get_flight_ticket_with_pagination(formData).then((response) => {
      setFlightsListing(response.data.data)
      setTotalPages(response.data.last_page)
    }).catch((error) => {
      let message = "";
      for (const key in error.data.errors) {
        if (error.data.errors.hasOwnProperty(key)) {
          const keyMessages = error.data.errors[key].map(message => message);
          message = message.concat(keyMessages);
        }
      }
      ErrorToast(message)
    })
  }
  const Columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",

    },
    {
      title: "Pnr",
      dataIndex: "pnr",
      key: "pnr",
    },
    {
      title: "Airline Name",
      dataIndex: "airline_name",
      key: "airline_name",
    },
    {
      title: "Pax Number",
      dataIndex: "pax_number",
      key: "pax_number",
    },
    {
      title: "Sector",
      dataIndex: "sector",
      key: "sector",
    },
    {
      title: "Issue Date",
      dataIndex: "issue_date",
      key: "issue_date",
    },
    {
      title: "Travel Date",
      dataIndex: "travel_date",
      key: "travel_date",
    },
    {
      title: "Return Date",
      dataIndex: "return_date",
      key: "return_date",
    },
    {
      title: "Status",
      dataIndex: "ecr_status_name",
      key: "ecr_status_name",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      action: (row) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton>
            <PiEye className='flight_ticket_action_view' onClick={() => {
              navigate(baseRouteUrl + `/${row.id}/view_flight_ticket`)
            }} />
          </IconButton>
          {/* Finance Action handle */}
          {userDetails.UD_roleID === 7 ? <>
            {row.ecr_status === 3 ? (
              <Tooltip title="Hold" placement="top">
                <IconButton onClick={() => {
                  setfinanceLevelModal(true)
                  setSelectedItem({ ...row, 'type': 'OnHold' })
                }}>
                  <FaRegCirclePause className='flight_ticket_action_onhold' />
                </IconButton>
              </Tooltip>
            ) : <></>}
            {row.ecr_status === 3 || row.ecr_status === 7 ? (
              <Tooltip title="Verified" placement="top">
                <IconButton onClick={() => {
                  setfinanceLevelModal(true)
                  setSelectedItem({ ...row, 'type': 'Verified' })
                }}>
                  <SiTicktick className='flight_ticket_action_verified' />
                </IconButton>
              </Tooltip>
            ) : <></>}
          </> : <></>}

          {/* Manager Action handle  and also check submitted status  */}
          {userDetails.UD_roleID === 4 && row.ecr_status === 2 ? <>
            <Tooltip title="Approve" placement="top">
              <IconButton>
                <TiTickOutline className='flight_ticket_action_approve' onClick={() => {
                  setSelectedItem({ ...row, 'type': 'Approve' })
                  setActionModal(true)
                }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Reject" placement="top">
              <IconButton>
                <IoCloseCircleOutline className='flight_ticket_action_reject' onClick={() => {
                  setSelectedItem({ ...row, 'type': 'Reject' })
                  setActionModal(true)
                }} />
              </IconButton>
            </Tooltip>
          </> : <></>}

          {/* Staff Action handle */}
          {userDetails.UD_roleID === 6 ? <>
            {(row.ecr_status == 1 || row.ecr_status == 6) ? (
              <IconButton>
                <BiEditAlt className='flight_ticket_action_edit' onClick={() => {
                  navigate(baseRouteUrl + `/${row.id}/update_flight_ticket`)
                }} />
              </IconButton>

            ) : <></>}
            {row.ecr_status == 5 ? (
              <>
                <IconButton>
                  <IoCloseCircleOutline className='flight_ticket_action_cancel_ticket' onClick={() => {
                    setSelectedItem(row)
                    setCancelModal(true)
                  }} />
                </IconButton>
                <IconButton>
                  <RiLoopLeftFill className='flight_ticket_action_re_shedule' onClick={() => {
                    setSelectedItem(row)
                    setDateChangeModal(true)
                  }} />
                </IconButton>
              </>
            ) : <></>}
          </> : <></>}

          {userDetails.UD_roleID === 8 && row.ecr_status !== 6 && row.ecr_status !== 5 ? <>
            <Tooltip title="Approve" placement="top">
              <IconButton>
                <TiTickOutline className='flight_ticket_action_approve' onClick={() => {
                  setSelectedItem({ ...row, 'type': 'Approve' })
                  setCfoLevelModal(true)
                }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Reject" placement="top">
              <IconButton>
                <IoCloseCircleOutline className='flight_ticket_action_reject' onClick={() => {
                  setSelectedItem({ ...row, 'type': 'Reject' })
                  setCfoLevelModal(true)
                }} />
              </IconButton>
            </Tooltip>
          </> : <></>}
          {row.ecr_status != 1 && (
            <IconButton>
              <FaRegCommentAlt className='flight_ticket_action_comment' onClick={() => {
                setSelectedItem({ ...row })
                setcommentModal(true)
              }} />
            </IconButton>
          )}
          {row.ecr_status === 4 || row.ecr_status === 5 ? <>
            <Tooltip title="Invoice" placement="top">
              <IconButton>
                <LiaFileInvoiceSolid className='flight_ticket_action_invoice' onClick={() => {
                    setInvoiceDetailsModal(true)
                    setSelectedItem(row?.all_data)
                }} />
              </IconButton>
            </Tooltip>
            </>
          :<></>}

        </div>
      ),
    },
  ];


  const datas = () => {
    const result =
      flightsListing && flightsListing.map((item, index) => {
        return {
          key: pageNumber * totalItemPerPage - (totalItemPerPage - (index + 1)),
          id: item.FBD_Id,
          ecr_status: item.FBD_ECRstatusID,
          ecr_status_name: item?.FBD_cancel_status===1?'Partially Cancelled':item?.ecr_status?.ESL_name,
          pnr: item.FBD_PNR,
          pax_number: item.FBD_pax_count,
          sector: item.FBD_sector,
          issue_date: item.FBD_issue_date ? item.FBD_issue_date.split('T')[0] : item.FBD_issue_date,
          travel_date: item.FBD_travel_date ? item.FBD_travel_date.split('T')[0] : item.FBD_travel_date,
          return_date: item.FBD_return_date ? item.FBD_return_date.split('T')[0] : item.FBD_return_date,
          airline_name: item?.FBD_FlightName,
          all_data: item
        };
      });
    return result;
  };

  const handleModalClose = (type, is_close) => {
    if (type == "Cancel") {
      setCancelModal(is_close)
      setSelectedItem(null)
    } else if (type == "DateChange") {
      setDateChangeModal(is_close)
      setSelectedItem(null)
    }
  };
  const updatePagination = (pageNumber) => {
    setPageNumber(pageNumber)
  }
  const updateItemPageCount = (pageItemCount) => {
    setTotalItemPerPage(pageItemCount)
  }
  const searchfilter = (value) => {
    setSearchFilter(value)
    if (value) {
      get_booking_list(pageNumber, value)
    } else {
      dispatch(resetSearchFilter())
      setPageNumber(1)
    }
  }

  const updateCheckedColumn = (index) => {
    dispatch(updateColumCheckList(index))
  }
  const handleActionModalClose = (is_close) => {
    setActionModal(is_close)
    setSelectedItem(null)
  };
  const handleCommentModalClose = (is_close) => {
    setcommentModal(is_close)
    setSelectedItem(null)
  };
  const handleFinanceLevelModalClose = (is_close) => {
    setfinanceLevelModal(is_close)
    setSelectedItem(null)
  };
  const handleCfoLevelModalClose = (is_close) => {
    setCfoLevelModal(is_close)
    setSelectedItem(null)
  };
  const handleInvoiceDetailsModalClose = (is_close) => {
    setInvoiceDetailsModal(is_close)
    setSelectedItem(null)
  };
  return (
    <div className='flight_ticket_module_main_container'>
      <CustomTableColumnSelector columns={Columns.filter((item) => item.dataIndex !== "key")} checkedColumns={defaultColumnChecked} onChange={updateCheckedColumn} />
      <div>
        <div className='flight_ticket_module_header'>
          <p className='flight_ticket_title'>Flight Tickets</p>
          {userDetails?.UD_roleID === 6 && (
            <button className='flight_ticket_add_button' onClick={() => {
              navigate(baseRouteUrl + `/create_new_flight_ticket`)
            }}>New</button>
          )}

        </div>
        <div>
          <FlightTicketTableFilter searchFilter={searchfilter} />
        </div>
      </div>
      <CustomTable columns={Columns.filter((_, index) => defaultColumnChecked.includes(index))} rows={datas()} />
      <div>
        <div>
          <CustomBottomPagination total_items_per_page={totalItemPerPage} total_page_count={totalPages} current_page={pageNumber} on_change_value={updatePagination} on_page_count_update={updateItemPageCount} />
        </div>
        <FlightTicketModals selected_item={selectedItem} open_cancel_modal={cancelModal} open_date_change_modal={dateChangeModal} handle_modal_close={handleModalClose} />
        <FlightTicketManagerLevelModal selected_item={selectedItem} open_modal={actionModal} modal_close={handleActionModalClose} />
        <FlightTicketCommentModal open={commentModal} handleClose={handleCommentModalClose} booking_id={selectedItem?.id} />
        <FlightTicketFinanceLevelModals selected_item={selectedItem} open_modal={financeLevelModal} modal_close={handleFinanceLevelModalClose} />
        <FlightTicketCfoLevelModal selected_item={selectedItem} open_modal={cfoLevelModal} modal_close={handleCfoLevelModalClose} />  
        {invoiceDetailsModal &&  <FlightTicketInvoiceDetailsModal selected_item={selectedItem} open_modal={invoiceDetailsModal} modal_close={handleInvoiceDetailsModalClose}/>}
       
      </div>
    </div>
  )
}

export default FlightTickets