import React, { useEffect, useState } from 'react'
import CustomModal from '../../../../../components/custom_modal/CustomModal'
import { IconButton } from '@mui/material';
import { IoIosCloseCircleOutline } from "react-icons/io";
import FlightTicketDateChange from '../flight_ticket_date_change/FlightTicketDateChange';
import FlightCancelForm from '../flight_cancel_form/FlightCancelForm';

function FlightTicketModals({ selected_item, open_cancel_modal, open_date_change_modal ,handle_modal_close}) {
    const [cancelModal, setCancelModal] = useState(false);
    const [dateChangeModal, setDateChangeModal] = useState(false);
    useEffect(() => {
        if (open_cancel_modal) {
            setCancelModal(open_cancel_modal)
        } else if (open_date_change_modal) {
            setDateChangeModal(open_date_change_modal)
        }
    }, [open_cancel_modal, open_date_change_modal])
    const handleCancelModal = (is_close) => {
        setCancelModal(is_close);
        handle_modal_close('Cancel',is_close)
    };
    const handleDateChangeModal = (is_close) => {
        setDateChangeModal(is_close);
        handle_modal_close('DateChange',is_close)
    };
    return (
        <div>
            <CustomModal
                maxWidth={"1500px"}
                open={cancelModal}
                handleClose={handleCancelModal}
                close={<IconButton
                    aria-label="close"
                    onClick={() => {
                        handleCancelModal(false)
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                > <IoIosCloseCircleOutline />
                </IconButton>}
                body={
                    <FlightCancelForm details={selected_item?.all_data} onClose={handleCancelModal} />
                }
                title={"Cancel Ticket"}
            />

            <CustomModal
                 maxWidth={"1500px"}
                open={dateChangeModal}
                handleClose={handleDateChangeModal}
                close={<IconButton
                    aria-label="close"
                    onClick={() => {
                        handleDateChangeModal(false)
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                > <IoIosCloseCircleOutline />
                </IconButton>}
                body={
                    <FlightTicketDateChange details={selected_item?.all_data} onClose={handleDateChangeModal}/>
                }
                title={"Date Reshedule"}
            />
        </div>
    )
}

export default FlightTicketModals